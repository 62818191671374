import { Header, Header2 } from './../components/header/';

export const Layout1 = (props) => {
    const { children } = props;

    return (
        <>
            <Header />
            {children}
        </>
    );
}

export const Layout2 = (props) => {
    const { handleModal, children } = props;

    return (
        <>
            <Header2 handleModal={handleModal} />
            {children}
        </>
    );
}
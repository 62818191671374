import styles from "./index.module.css";
import Img1Smart from "./../../../assets/images/smart_pos.gif";
// import Icons from "../../icons";

function SectionSmartPos() {
  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className="containbox">
          <div className={styles.content}>
            {/* <div className={styles.badge}>
              <span>
                <Icons smartBadge />
              </span>
            </div> */}
            <h2>Virtual restaurants</h2>
            <p className={`${styles.h5} h5`}>
              ZEWST helps business owners launch multiple virtual brands and
              cloud kitchens with a sleek design, intuitive interface,
              convenience and portability. The communal kitchen platform allows
              you to develop your own online presence so that you can start
              receiving orders without having to use online ordering solutions
              from any third party.
            </p>
          </div>
        </div>
        <div className={styles.imgbox}>
          <img src={Img1Smart} alt="Smart POS GIF" />
        </div>
      </article>
    </section>
  );
}

export default SectionSmartPos;

import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>Privacy Policy</h2>
          <p>
            <strong>Introduction and scope</strong>
          </p>
          <p className="no_space">
            This Privacy Statement describes how Zewst, Inc. and its
            subsidiaries and affiliates (collectively “Zewst”, “we”, “us” and/or
            “our”) collects and manages your personal information (i.e., any
            information that relates to an identified or identifiable
            individual) as part of providing our Services (defined below). This
            Statement primarily covers:
          </p>
          <ul className="list1">
            <li>
              <p>
                Merchants: businesses that have expressed interest in using the
                Services or have contracted with Zewst to provide the Services
                within their restaurants;
              </p>
            </li>
            <li>
              <p>
                Merchant Employees: employees of our Merchants that use the
                Services; and
              </p>
            </li>
            <li>
              <p>
                Guests: individuals that use the Services at one of our
                Merchant’s restaurants or directly through Zewst.
              </p>
            </li>
          </ul>
          <p>
            Please note that our Merchants are independent third parties that
            maintain their own business practices and policies outside of their
            relationship with Zewst and their use of the Services. As a result,
            unless provided otherwise in this Statement, we are not responsible
            for the privacy policies or data practices of our Merchants, who may
            maintain separate policies and practices. If you are a Merchant
            Employee, your employer is responsible for providing any additional
            required notices or information to you regarding its privacy
            practices outside of this Statement.
          </p>
          <p>
            By using the Services and/or providing us with your personal
            information, you acknowledge that your personal information will be
            processed and used in the manner set out in this Privacy Statement.
            We may amend this Statement from time to time in line with the
            “Updates to this Privacy Statement” section below. To the extent
            permitted by law, your continued use of the Services and Websites
            constitutes your acceptance to those amendments and the updated
            Statement.
          </p>

          <p>
            <strong>Definitions</strong>
          </p>
          <p className="no_space">
            Here are a few other terms we use throughout this Privacy Statement
            that you should know:
          </p>
          <ul className="list1">
            <li>
              <p>
                “Zewst Payroll and Team Management” refers to a module offered
                as part of the Services directed to Merchant Employees that
                includes a number of HR-focused services, including, but not
                limited to payroll, benefits administration, card services,
                scheduling and applicant tracking services.
              </p>
            </li>
            <li>
              <p>
                “Services” refers to services and products (including both
                hardware and software) developed or administered by us from
                time-to-time, including:
              </p>
              <ul>
                <li>
                  <p>our core Zewst online website, system;</p>
                </li>
                <li>
                  <p>payment processing services;</p>
                </li>
                <li>
                  <p>our application programming interfaces (“APIs”);</p>
                </li>
                <li>
                  <p>
                    associated modules provided as part of our eco system, such
                    as our loyalty, marketing, delivery and Team Management
                    modules;
                  </p>
                </li>
                <li>
                  <p>
                    our digital ordering services, such as online ordering,
                    pickup and delivery services, contactless order and pay at
                    the table functionality, gift cards and our mobile
                    application(s);
                  </p>
                </li>
                <li>
                  <p>
                    accounts created through our digital ordering services
                    (“Digital Ordering Account(s)”);
                  </p>
                </li>
                <li>
                  <p>
                    o other mobile application(s) developed as part of the
                    Services, including our Merchant and Merchant
                    Employee-facing mobile applications (e.g., the Zewst-Lite
                    mobile application);
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            “You” and/or “your” is a Merchant, a Merchant Employee, a Guest, a
            visitor to one of our Websites or other covered data subject.
          </p>
          <p>
            <strong>Personal information we collect</strong>
          </p>
          <p className="no_space">
            What personal information we collect will depend on the nature of
            your interaction with the Services and our Websites. While some
            information is collected automatically or through sources outside of
            Zewst, most is collected when you use our Services or our Websites.
            A breakdown of the collection has been provided in the sections
            below.
          </p>
          <p>Personal information collected through the Services</p>
          <p>
            <strong>Merchants</strong>
          </p>
          <p className="no_space">
            If you are a Merchant, we will collect personal information from you
            in connection with your service agreement and use (or prospective
            use) of the Services, including, as applicable,
          </p>
          <ul className="list1">
            <li>
              <p>your name</p>
            </li>
            <li>
              <p>address</p>
            </li>
            <li>
              <p>email</p>
            </li>
            <li>
              <p>date of birth; and</p>
            </li>
            <li>
              <p>phone number</p>
            </li>
          </ul>
          <p>
            As part of our application process and agreement to provide the
            Services, we will also collect additional information, such as your
            tax identification number, national identification number (e.g.
            Social Security number or passport number), your drivers’ license
            details as well as your banking payment card information,
            transaction history as part of the Service.
          </p>
          <p>
            If you are a business partner that is looking to integrate with
            Zewst, we will also collect information, such as your name and
            contact details as part of your application to integrate with our
            Services.
          </p>
          <p>
            <strong>Merchant Employees</strong>
          </p>
          <p>
            If you are a Merchant Employee, we collect personal information
            about you through your use of the Services. This includes
          </p>
          <ul className="list1">
            <li>
              <p>your name</p>
            </li>
            <li>
              <p>email</p>
            </li>
            <li>
              <p>phone number</p>
            </li>
            <li>
              <p>employee identification number</p>
            </li>
            <li>
              <p>address</p>
            </li>
            <li>
              <p>date of birth; and</p>
            </li>
            <li>
              <p>
                information relating to your role, such as your job title, wage
                rates and salary and hours worked.
              </p>
            </li>
          </ul>
          <p>
            To the extent you are employed by a Merchant that uses the Zewst
            Payroll and Team Management module, we may also collect:
          </p>
          <ul className="list1">
            <li>
              <p>
                your Social Security number or other national identification
                number;
              </p>
            </li>
            <li>
              <p>banking information as part of payroll;</p>
            </li>
            <li>
              <p>your professional and educational history;</p>
            </li>
            <li>
              <p>tax documentation such as your W2 and 1095 tax forms;</p>
            </li>
            <li>
              <p>your benefit elections;</p>
            </li>
            <li>
              <p>driver’s license information;</p>
            </li>
            <li>
              <p>gender;</p>
            </li>
            <li>
              <p>marital status;</p>
            </li>
            <li>
              <p>disability status;</p>
            </li>
            <li>
              <p>ethnicity; and</p>
            </li>
            <li>
              <p>your dependent and beneficiary information.</p>
            </li>
          </ul>
          <p>
            Please note that the actual personal information collected will
            depend on the specific Team Management services that you or your
            employer has elected to use. Please contact your employer for
            additional information.
          </p>
          <p>
            <strong>Guests</strong>
          </p>
          <p className="no_space">
            We collect information from you through your use of the Services (as
            provided and developed by us from time to time), which includes the
            creation of a Digital Ordering Account, your use of our online
            ordering features and mobile application(s) and other related
            products, such as our pickup, delivery and order and pay at the
            table services. We may also collect and/or receive your personal
            information when you place an order with, make a purchase from
            (including gift cards), or otherwise complete a transaction with our
            Merchants or participate in their respective loyalty programs.
          </p>
          <p>
            Depending on which Service you have used, personal information
            collected may include:
          </p>
          <ul className="list1">
            <li>
              <p>your name;</p>
            </li>
            <li>
              <p>contact details such as your phone number and email;</p>
            </li>
            <li>
              <p>your address and other general location details;</p>
            </li>
            <li>
              <p>
                your payment card information, such as the brand, card number,
                security code and expiration date;
              </p>
            </li>
            <li>
              <p>
                transaction information and details (e.g., history of
                goods/services ordered, date, payment method and amount of
                payment);
              </p>
            </li>
            <li>
              <p>your date of birth (if you choose to provide it);</p>
            </li>
            <li>
              <p>
                information about your vehicle (for users of our curbside pickup
                service);
              </p>
            </li>
            <li>
              <p>
                account and profile information such as your username and
                password;
              </p>
            </li>
            <li>
              <p>
                if you are a member of a Merchant’s loyalty program, information
                in relation to your points balance and redemptions; and
              </p>
            </li>
            <li>
              <p>
                your feedback in relation to your experience at our Merchants’
                establishments (if you choose to provide it).
              </p>
            </li>
          </ul>
          <p>
            In all cases, the actual personal information collected will vary
            depending on the Services being used
          </p>
          <p>Personal information collected through our Websites</p>
          <p className="no_space">
            In addition to using the Services, we may also collect personal
            information when you visit our Websites and request information
            about our Services, download a white paper, schedule a product demo
            or subscribe to our media channels (e.g., blogs, podcasts, etc.).
            This personal information may include:
          </p>
          <ul className="list1">
            <li>
              <p>your name;</p>
            </li>
            <li>
              <p>email; and</p>
            </li>
            <li>
              <p>phone number.</p>
            </li>
            <li>
              <p>Address</p>
            </li>
          </ul>
          <p>
            Certain information may also be collected automatically when you
            visit our Websites. For more information, please see the section of
            this Statement entitled “Information collected automatically.”
          </p>
          <p>
            Please note that additional information beyond what is described
            here will be collected (described in the Merchant section above) as
            part of our online Merchant application process or through our
            e-commerce Website.
          </p>
          <p>Personal information collected from other sources</p>
          <p className="no_space">
            Depending on whether you are a Merchant, a Merchant Employee, a
            Guest or a visitor to one of our Websites, we may also collect
            personal information about you from third parties including our
            business partners, data providers, identity verification services,
            credit bureaus (if applicable), banks and other financial
            institutions and credit card companies. We may also collect
            information from you that is publicly available. For example, if you
            interact with us through various social media channels.
          </p>
          <p>Information collected automatically </p>
          <p className="no_space">
            We collect information automatically when you visit our Websites,
            use our mobile application(s), complete a transaction, or use our
            online services such as online ordering. Information collected
            automatically by cookies, web beacons or other similar technologies
            (described in the “Cookies and other tracking technologies” section
            of this Statement) may include:
          </p>
          <ul className="list1">
            <li>
              <p>
                information about your device, such as your device type/model,
                number and device ID (e.g., MAC address);
              </p>
            </li>
            <li>
              <p>
                information about your browser, settings (e.g., language) and
                operating system;
              </p>
            </li>
            <li>
              <p>
                your internet protocol (IP) address (including, in some
                instances, your perceived location);
              </p>
            </li>
            <li>
              <p>unique advertising identifiers;</p>
            </li>
            <li>
              <p>transactional and purchase information; and</p>
            </li>
            <li>
              <p>
                browsing and usage activity, such as the referring domain, what
                websites/content you have viewed or actions you have taken on a
                particular website.
              </p>
            </li>
          </ul>
          <p>
            Depending on the Services being used or the websites you access, we
            may also collect geolocation information through your devices. For
            example, we may show you what restaurants in your area are available
            within our mobile application(s). This information may be collected
            via GPS, Bluetooth, cellular or WiFi technologies. You can adjust
            your settings at the device or browser level to disable the use of
            these technologies.
          </p>

          <p>
            <strong>How we use personal information</strong>
          </p>
          <p className="no_space">We use personal information to:</p>
          <ul className="list1">
            <li>
              <p>To provide, maintain and support our Services, including</p>
            </li>
            <ul>
              <li>
                <p>
                  to provide updates, support and training related to the
                  Services;
                </p>
              </li>
              <li>
                <p>
                  to determine the suitability of individuals in relation to
                  their use of certain Services;
                </p>
              </li>
              <li>
                <p>for contracting and agreement purposes;</p>
              </li>
              <li>
                <p>
                  to process transactions and payments through the Services;
                </p>
              </li>
              <li>
                <p>
                  to enable our Merchants and our Merchants Employees to access
                  and use the Services, including information that you have
                  provided as part of using the Services; and
                </p>
              </li>
              <li>
                <p>
                  to provide online services, including verifying your identity,
                  as well as diagnosing technical and service issues.
                </p>
              </li>
            </ul>
            <li>
              <p>
                To manage our business and for internal operational purposes,
                including
              </p>
              <ul>
                <li>
                  <p>analyzing the performance of our Services;</p>
                </li>
                <li>
                  <p>workforce development;</p>
                </li>
                <li>
                  <p>
                    creating and developing analytics for the benefit of our
                    business and the business of our Merchants;
                  </p>
                </li>
                <li>
                  <p>
                    research purposes, including the development of new
                    products;
                  </p>
                </li>
                <li>
                  <p>assessing the effectiveness of Services; and</p>
                </li>
                <li>
                  <p>improving our Services and Websites.</p>
                </li>
              </ul>
            </li>
            <li>
              <p>To personalize your experience, including</p>
              <ul>
                <li>
                  <p>
                    using transactional data and order histories to provide
                    recommendations when using our Services or those of our
                    Merchants; and
                  </p>
                </li>
                <li>
                  <p>
                    using analytics and profiling technology to personalize your
                    online experience on our Websites.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>To advertise and market to you, including</p>
              <ul>
                <li>
                  <p>
                    sending you marketing communications, either directly or
                    through a third party, in relation to our existing or new
                    Services that we think might interest you; and
                  </p>
                </li>
                <li>
                  <p>
                    enabling our Merchants or our business partners, either
                    directly or through a third party, to advertise their
                    products and services to you.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Any communications sent to you pursuant to this section shall either
            be permitted under the applicable law or with your consent. Please
            see the “Your rights and choices” section of this Statement for more
            details on opting out of these communications and updating your
            preferences.
          </p>
          <ul className="list1">
            <li>
              <p>
                To communicate with you or provide information you have
                requested, including
              </p>
              <ul>
                <li>
                  <p>to provide notifications in relation to your purchases;</p>
                </li>
                <li>
                  <p>
                    sending you white papers and other materials from our
                    Websites;
                  </p>
                </li>
                <li>
                  <p>
                    providing you with our newsletters, podcasts and other
                    subscription materials;
                  </p>
                </li>
                <li>
                  <p>sending you digital receipts; and</p>
                </li>
                <li>
                  <p>
                    responding to feedback that you have provided in relation to
                    your experience using one of our products or Services or
                    those of our Merchants.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                For legal, compliance and security-related purposes, including
                to
              </p>
              <ul>
                <li>
                  <p>
                    comply with our legal obligations, including under
                    anti-money laundering, know-your-customer or similar laws in
                    any relevant jurisdiction;
                  </p>
                </li>
                <li>
                  <p>secure and protect our network and systems;</p>
                </li>
                <li>
                  <p>identify and protect against fraud and other crimes;</p>
                </li>
                <li>
                  <p>establish, exercise or defend legal claims;</p>
                </li>
                <li>
                  <p>perform our contractual obligations; and</p>
                </li>
                <li>
                  <p>monitor and report compliance issues.</p>
                </li>
              </ul>
            </li>
          </ul>

          <p>
            <strong>How we share information</strong>
          </p>
          <p className="no_space">
            Zewst may share personal information as part of providing the
            Services and for the purposes described within this Statement. This
            includes:
          </p>
          <ul className="list1">
            <li>
              <p>
                with our Merchants and our Merchants' Employees for the purposes
                of providing the Services to you, fulfilling your requests and
                for the other purposes described in this Statement;
              </p>
            </li>
            <li>
              <p>
                with our business partners (including our integration partners)
                in order to provide, maintain and improve and expand our
                Services;
              </p>
            </li>
            <li>
              <p>
                with our parent, subsidiary, or affiliate companies, agents (if
                any) for the purposes outlined above;
              </p>
            </li>
            <li>
              <p>
                with third parties to provide, maintain and improve our
                Services, including service providers who access information
                about you to perform services on our behalf or on behalf of our
                Merchants, such as hosting and information technology services,
                payment services, identity verification and fraud prevention
                services, marketing and advertising services, data analytics and
                personalization services and customer support services. Please
                note:
              </p>
              <ul>
                <li>
                  <p>
                    If you are a Merchant Employee whose employer is using the
                    Zewst Payroll and Team Management module, we will share your
                    information with benefits, payroll and other
                    employment-related service providers.
                  </p>
                </li>
                <li>
                  <p>
                    If you are a Merchant that applies for financing through
                    Zewst’s platform, we will share your information (including
                    personal information) with the lender. As part of the
                    application, a credit report will also be requested from
                    third-party credit bureaus to determine your eligibility for
                    such financing.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                in connection with, or during the negotiation of, any merger,
                sale of company stock or assets, financing, acquisition,
                divestiture or dissolution of all or a portion of our business;
                or
              </p>
            </li>
            <li>
              <p>if we believe it is necessary to:</p>
              <ul>
                <li>
                  <p>
                    protect our rights or property, or the security or integrity
                    of our Services or our Websites;
                  </p>
                </li>
                <li>
                  <p>
                    enforce the terms of our terms of service or other
                    applicable agreements or policies;
                  </p>
                </li>
                <li>
                  <p>
                    protect us, users of our Services or the public from harm or
                    potentially prohibited or illegal activities;
                  </p>
                </li>
                <li>
                  <p>
                    investigate, detect and prevent fraud and security breaches;
                    or
                  </p>
                </li>
                <li>
                  <p>
                    comply with any applicable law, regulation, legal process or
                    governmental request.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            We may also share aggregated and/or anonymized information derived
            from the Services that does not directly identify you, including
            device information and information derived from cookies and log
            files with third parties for the purposes described in this
            Statement.
          </p>

          <p>
            <strong>Cookies and other tracking technologies</strong>
          </p>
          <p className="no_space">
            Zewst and third parties described in this Statement may use cookies,
            web beacons and other tracking technologies as part of providing the
            Services and for the purposes described in this Statement. This
            includes:
          </p>
          <ul className="list1">
            <li>
              <p>
                Cookies: a small text file placed and saved in your browser when
                you access our Websites and potentially the websites of our
                Merchants, business partners and other third parties. We use
                both session cookies (i.e., cookies that are stored only for a
                specific website visit) and persistent cookies (i.e., cookies
                that are stored beyond a specific website visit) to provide the
                Services and for the purposes described in this Statement. These
                cookies may be set by us (first-party cookies) or set by third
                parties that collect information on our behalf (third-party
                cookies), such as Google Analytics.
              </p>
            </li>
            <li>
              <p>
                Web beacons: a small graphic file placed on a website (or
                websites) or email that tracks your activity and monitors your
                behavior. We use web beacons within our Services to collect
                usage and performance data.
              </p>
            </li>
          </ul>
          <p>
            As part of using the Services, we use these technologies as well as
            similar technologies within our Services and across our Websites.
            Examples include:
          </p>
          <ul className="list1">
            <li>
              <p>
                to provide our Services (e.g., authentication within the
                check-out process);
              </p>
            </li>
            <li>
              <p>to uniquely identify you and/or your device;</p>
            </li>
            <li>
              <p>
                to store your preferences as part of providing the Services;
              </p>
            </li>
            <li>
              <p>
                for personalization and targeted advertising purposes (including
                across your devices and applications);
              </p>
            </li>
            <li>
              <p>for security and fraud-prevention purposes;</p>
            </li>
            <li>
              <p>to analyze and monitor the performance of our Services;</p>
            </li>
            <li>
              <p>to improve and develop new Services; and</p>
            </li>
            <li>
              <p>to understand your use of the Services over time.</p>
            </li>
          </ul>
          <p>
            There are ways to control and/or reject the setting of cookies and
            similar technologies within your browser settings. As each browser
            is different, please consult the “help” menu within your browser.
            For additional information about cookies and how to control their
            use on various browsers and devices, you can visit
            http://www.allaboutcookies.org. Please be aware that depending on
            the Services being used, restricting cookies may prevent you from
            accessing and using all or part of the Services.
          </p>
          <p>Your choices about online ads</p>
          <p className="no_space">
            We support the self-regulatory principles for online advertising
            (“Principles”) published by the Digital Advertising Alliance
            (“DAA”). This means that we allow you to exercise your choice
            regarding the collection of information about your online activities
            over time and across third-party websites for online interest-based
            advertising purposes. More information about these Principles can be
            found at{" "}
            <a href="https://youradchoices.com/" target="_blank">
              www.aboutads.info
            </a>
            . If you want to opt out of receiving online interest-based
            advertisements on your internet browser from advertisers and third
            parties that participate in the DAA program and perform
            advertising-related services for us and our partners, please follow
            the instructions at{" "}
            <a href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank">
              www.aboutads.info/choices
            </a>
            , or &nbsp;
            <a
              href="https://optout.networkadvertising.org/?c=1"
              target="_blank"
            >
              http://www.networkadvertising.org/choices/
            </a>{" "}
            to place an opt-out cookie on your device indicating that you do not
            want to receive interest-based advertisements. Opt-out cookies only
            work on the specific internet browser and device that they are
            downloaded onto. If you want to opt out of interest-based
            advertisements across all your browsers and devices, you will need
            to opt out on each browser on each device you actively use. If you
            delete cookies on your device generally, you will need to set the
            opt-out cookie again on that device. If you want to opt out of
            receiving online interest-based advertisements on mobile
            applications, please follow the instructions at{" "}
            <a href="https://youradchoices.com/" target="_blank">
              http://www.aboutads.info/appchoices
            </a>
            . Please note that when you opt out of receiving interest-based
            advertisements, this does not mean you will no longer see
            advertisements from us or on our online services. It means that the
            online ads that you do see from DAA program participants should not
            be based on your interests. We are not responsible for the
            effectiveness of, or compliance with, any third-parties’ opt-out
            options or programs or the accuracy of their statements regarding
            their programs. In addition, third parties may still use cookies to
            collect information about your use of our online services, including
            for analytics and fraud prevention as well as any other purpose
            permitted under the DAA’s Principles.
          </p>
          <p>Do not track</p>
          <p className="no_space">
            We may use, and we may allow third-party service providers and other
            third parties to use, cookies or other technologies on our Services
            that collect information about your browsing activities over time
            and across different websites following your use of the Services. Do
            Not Track (“DNT”) is an optional browser setting that allows you to
            express your preferences regarding tracking across websites. We
            currently do not respond to DNT signals. We may continue to collect
            information in the manner described in this Privacy Statement from
            web browsers that have enabled DNT signals or similar mechanisms.
          </p>

          <p>
            <strong>Your rights and choices</strong>
          </p>
          <p className="no_space">Managing your information</p>
          <p className="no_space">
            We want to ensure that you have the necessary tools at your disposal
            to manage your personal information. It is also important that you
            ensure that you information is accurate and up to date. Your ability
            to update and manage your personal information will differ depending
            on your relationship with Zewst and what Services you use. For
            example,
          </p>
          <ul className="list1">
            <li>
              <p>
                As a Merchant, for certain services, you may access, change or
                correct certain account information at any time by logging into
                your account. In other instances, please contact our customer
                success team.
              </p>
            </li>
            <li>
              <p>
                As a Merchant Employee using the Zewst Payroll and Team
                Management module or other Merchant Employee-facing Services,
                you have the ability in many cases to access and update your
                information through the Services. In other instances, please
                reach out to your Merchant Employer.
              </p>
            </li>
            <li>
              <p>
                As a Guest, depending on the Services you use, you may be able
                to access, change and update your information through an account
                created as part of the Services (e.g., a Digital Ordering
                Account). If you are a Guest and would like to have your account
                deleted or have other questions about your Digital Ordering
                Account, please contact{" "}
                <a href="mailto:info@zewst.com" target="_blank">
                  info@zewst.com
                </a>
              </p>
            </li>
          </ul>
          <p>
            In other instances, if applicable, see the instructions provided as
            part of the Services or contact us as described in the “How to
            contact us” section of this Statement. We may need to verify your
            identity before changing or correcting your information. In certain
            instances, we may not be able to make the correction or accommodate
            the request due to legal, contractual or technical restrictions.
          </p>
          <p>
            Please note that depending on your status and location, you may be
            entitled to additional information rights in relation to the
            processing of your personal information. For more information
            regarding these rights, and the locations where these rights are
            available, please see the applicable addendums in this Statement.
          </p>
          <p>Managing communications</p>
          <p className="no_space">
            As part of providing the Services, Zewst (whether directly or
            through a third party), may send you:
          </p>
          <ul className="list1">
            <li>
              <p>
                Marketing communications: Depending on the nature of our
                relationship and the Services being used, we may send you
                marketing and other promotional communications for new or
                existing Services that we think you might be interested in.
                These marketing communications may include marketing text
                messages if you have opted in to receiving them. You can opt out
                of or unsubscribe from any marketing communications by following
                the instructions in those messages, by changing your
                communications preferences within your account or through your
                device. You can also opt out by contacting us at{" "}
                <a href="mailto:info@zewst.com" target="_blank">
                  info@zewst.com
                </a>
                Opting out of one communication will not necessarily opt you out
                of all marketing communications. Please note that you may still
                receive certain non-marketing communications after opting out.
                These messages may include transaction-specific communications,
                messages as part of a loyalty program or account-specific
                communications. If you are located in Ireland or Canada, we will
                not send you direct marketing communications without your opt-in
                consent or as otherwise permitted under the applicable law.
                Additional information about this practice in Ireland is set out
                in the Ireland addendum in this Statement. In certain cases, our
                Merchants may also send you marketing and promotional
                communications as part of the Services, including when you visit
                a Merchant using Zewst or join a Merchant-specific loyalty
                program. In these instances, please follow the instructions
                within those messages or reach out to the Merchant directly.
              </p>
            </li>
            <li>
              <p>
                Other communications: As part of your interaction with our
                Services, you may receive various non-marketing communications
                from Zewst. These include:
              </p>
              <ul>
                <li>
                  <p>
                    sending you digital receipts via email or by text message;
                  </p>
                </li>
                <li>
                  <p>
                    notifications sent by Merchants as part of our Services,
                    such as order status, delivery or pick up notifications;
                  </p>
                </li>
                <li>
                  <p>
                    responding to feedback that you have provided in relation to
                    the Services of Zewst or one of our Merchants;
                  </p>
                </li>
                <li>
                  <p>
                    responding to feedback that you have provided in relation to
                    the Services of Zewst or one of our Merchants;
                  </p>
                </li>
                <li>
                  <p>
                    messages associated with contests, competitions or
                    promotions that you have elected to participate in.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                In certain cases, depending on the nature of your relationship
                with Zewst and the Services being used, you may also receive
                messages from third-party service providers and business
                partners.
              </p>
            </li>
          </ul>
          <p>
            For additional information about how we communicate with you, please
            contact us at{" "}
            <a href="mailto:info@zewst.com" target="_blank">
              info@zewst.com
            </a>
          </p>

          <p>
            <strong>Security</strong>
          </p>
          <p className="no_space">
            We implement appropriate administrative, technical and
            organizational security measures to protect your personal
            information against unauthorized access, disclosure, damage or loss.
            However, even though we have taken measures to protect your personal
            information, we cannot guarantee that the collection, transmission
            and storage of personal information will always be completely
            secure.
          </p>

          <p>
            <strong>Links to other websites</strong>
          </p>
          <p className="no_space">
            This Privacy Statement only applies to information collected when
            visiting our Websites or otherwise using our Services. While
            visiting our Websites or using the Services, you may be directed
            through links to third-party websites or services that are not
            operated or controlled by us. For example, the websites of our
            Merchants or business partners that provide services as part of this
            Statement. We are not responsible for the privacy practices and
            policies of these third parties. As a result, we encourage you to
            review the privacy policies of these third-party websites as their
            practices may differ from ours.
          </p>

          <p>
            <strong>Children</strong>
          </p>
          <p className="no_space">
            Our Services are not targeted or directed at children under the age
            of 13, and we do not intend to, or knowingly, collect or solicit
            personal information from children under the age of 13. If you have
            reason to believe that a child under the age of 13 has provided
            personal information to us, we encourage the child’s parent or
            guardian to contact us as described in the “How to Contact Us”
            section of this Statement to request that we remove the information
            from our systems. If we learn that any personal information we
            collected has been provided by a child under the age of 13, we will
            promptly delete that personal information.
          </p>
          <p>
            We do, however, process personal information about children when it
            is necessary for the services we are offering, and you provide it to
            us
          </p>

          <p>
            <strong>How to contact us</strong>
          </p>
          <p className="no_space">
            If you have questions or concerns about our Privacy Statement or our
            practices, you can reach us at:
          </p>
          <ul className="list1">
            <li>
              <p>
                By email:{" "}
                <a href="info@zewst.com" target="_blank">
                  info@zewst.com
                </a>
                .{" "}
              </p>
            </li>
            <li>
              <p>By post: Zewst, 800 Third Avenue, 1024, New York NY 10022</p>
            </li>
            <li>
              <p>By phone: (888) 697-8526</p>
            </li>
          </ul>

          <p>
            <strong>Changes to this Privacy Statement</strong>
          </p>
          <p className="no_space">
            From time to time, we may revise this Privacy Statement in order to
            comply with the applicable law or our changing business practices.
            Unless we are required by the applicable law to provide a prescribed
            form of notice and/or obtain consent, updated versions of this
            Statement will be posted on this website.
          </p>

          <Link to="/" className="button" color="black-border">
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default PrivacyPolicy;

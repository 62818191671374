import { useState } from "react";
import { requestDemo } from "../../actions/RequestDemoAction";
import Icons from "../icons";
import styles from "./index.module.css";

function RequestModal(props) {
  const { handleModal } = props;
  const [name, setName] = useState("");
  const [resturant, setResturant] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestSubmit, setRequestSubmit] = useState(false);

  const handleAction = async (e) => {
    e.preventDefault();
    if (
      name.trim().length === 0 ||
      resturant.trim().length === 0 ||
      email.trim().length === 0 ||
      phone.trim().length === 0 ||
      address.trim().length === 0
    ) {
      alert("Kindly fill all fields");
      return;
    }

    setIsLoading(true);
    try {
      const response = await requestDemo({ name, resturantName: resturant, email, phone, address });
      console.log(response);
      if(response === 200) {
        setIsLoading(false);
        setRequestSubmit(true);
        clearAllFields();
      } else {
        setIsLoading(false);
        clearAllFields();
        alert(response);
        handleModal();
      }
    } catch(err) {
      alert(err);
      handleModal();
    }
  };

  const clearAllFields = () => {
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setResturant("");
  };

  return (
    <>
      <article className={styles.article}>
        <div className={styles.backdrop} onClick={handleModal}></div>
        <button className={styles.button} onClick={handleModal}>
          <Icons closeModal />
        </button>
        <div className={styles.modalbox}>
          <div className={styles.title}>
            <h2>Request A Demo</h2>
          </div>
          {requestSubmit ? 
            <>
              <div className={styles.successbox}>
                <div className={styles.successcontent}>
                  <div className={styles.iconbox1}>
                    <Icons thankyou />
                  </div>
                  <div className={styles.successText}>We have received your request. We will get back to you shortly.<br /><br />Zewst Team</div>
                </div>
                <button
                  onClick={handleModal}
                  className="button buttonLg"
                  color="white-border"
                >
                  Close
                </button>
              </div>
            </>
            : 
            <>
              <form onSubmit={handleAction}>
                <div className="inputgroup">
                  <div className="fieldbox">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name=""
                      id="fullName"
                      placeholder=" "
                    />
                    <label htmlFor="fullName">Full Name</label>
                    <span className="ikon">
                      <Icons user />
                    </span>
                  </div>
                </div>
                <div className="inputgroup">
                  <div className="fieldbox">
                    <input
                      value={resturant}
                      onChange={(e) => setResturant(e.target.value)}
                      type="text"
                      name=""
                      id="restaurantName"
                      placeholder=" "
                    />
                    <label htmlFor="restaurantName">Name of your Restaurant</label>
                    <span className="ikon">
                      <Icons restaurant />
                    </span>
                  </div>
                </div>
                <div className="inputgroup">
                  <div className="fieldbox">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name=""
                      id="email"
                      placeholder=" "
                    />
                    <label htmlFor="email">Your Email</label>
                    <span className="ikon">
                      <Icons email />
                    </span>
                  </div>
                </div>
                <div className="inputgroup">
                  <div className="fieldbox">
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="number"
                      name=""
                      id="phoneNumber"
                      placeholder=" "
                    />
                    <label htmlFor="phoneNumber">Your Phone Number</label>
                    <span className="ikon">
                      <Icons phone />
                    </span>
                  </div>
                </div>
                <div className="inputgroup">
                  <div className="fieldbox">
                    <input
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                      name=""
                      id="address"
                      placeholder=" "
                    />
                    <label htmlFor="address">Address</label>
                    <span className="ikon">
                      <Icons address />
                    </span>
                  </div>
                </div>
                <button
                  disabled={isLoading}
                  className="button buttonLg"
                  color="white-border"
                >
                  Submit
                </button>
              </form>
            </>
          }
        </div>
      </article>
    </>
  );
}

export default RequestModal;

import { Link } from "react-router-dom";

function TermsConditions() {
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>Terms of Services</h2>
          <p className="no_space">
            The following Terms of Service (“Terms” or “Terms of Service”) are a
            legal agreement between you (“you” or “your”) and Zewst, Inc.
            ("Zewst," "we," “us” or “our”) and govern your access to and use of
            our services, which include any websites, applications, digital
            ordering features, or any other ways Zewst allows you to engage with
            us directly or through a Merchant that uses Zewst (collectively,
            part of the “Services”). Our Services include, but are not limited
            to, our websites, Zewst Lite, any digital ordering feature that
            Zewst offers to Merchants, including a Merchant’s Online Ordering
            page and Payment Transactions facilitated on the Zewst point of sale
            or Capitalized terms used but not defined herein shall be as defined
            in the Merchant Agreement
          </p>
          <p>
            By accessing and/or using any of the Services, you agree to these
            Terms and any other policies or terms referenced within or posted
            throughout the Services, including but not limited to promotion
            terms, guidelines, as well as any rules or terms applicable to
            particular features or promotions, which are hereby expressly
            incorporated into these Terms by reference. You also acknowledge
            that you have read and understand our privacy statement (the
            “Privacy Statement”). FROM TIME TO TIME WE MAY UPDATE OR MODIFY
            THESE TERMS OF SERVICE IN OUR DISCRETION, AND WILL POST THE UPDATED
            TERMS SUCH UPDATES MAY BE REQUIRED IN ORDER TO REFLECT ENHANCEMENTS
            TO OUR SERVICES OR OUR WEBSITE. IF OUR CHANGES REDUCE YOUR RIGHTS OR
            INCREASE YOUR RESPONSIBILITIES, WE WILL USE COMMERCIALLY REASONABLE
            EFFORTS TO PROVIDE YOU WITH NOTICE BY EMAIL OR THROUGH THE TAKEOUT
            APP, IN ACCORDANCE WITH ANY NOTIFICATION PREFERENCES YOU HAVE
            PROVIDED. THE UPDATED TERMS OF SERVICE WILL BECOME EFFECTIVE AS OF
            THE EFFECTIVE DATE INDICATED IN THE TERMS OF SERVICE (“EFFECTIVE
            DATE”). ANY USE OF THE SERVICES AFTER THE EFFECTIVE DATE MEANS YOU
            HAVE ACCEPTED THE UPDATED TERMS. YOUR SOLE AND EXCLUSIVE REMEDY IN
            THE EVENT YOU DO NOT ACCEPT THE UPDATED TERMS OF SERVICE IS TO CEASE
            YOUR ACCESS TO AND USE OF THE SERVICES AND OUR WEBSITE.
          </p>
          <p>
            These Terms include an agreement to resolve disputes by arbitration,
            which means that you and Zewst waive the right to a trial by jury
            and agree to resolve any and all disputes through binding
            arbitration on an individual basis. If you are an individual, you
            must be at least 18 years of age, a U.S. or Canadian resident, and
            legally capable of entering into contracts. If you are an entity,
            organization, or company, or acting on behalf of one, you represent
            and warrant that you are an authorized representative of such
            entity, and that you have the authority to and agree to bind it to
            these Terms. You represent that you will not use the Services,
            directly or indirectly, for any fraudulent undertakings. When you
            pay for a Purchase made through Point of Sale, or Digital Ordering,
            you authorize Zewst to charge your credit or debit card or other
            permitted payment method (“Payment Method”) on behalf of the
            participating Merchant from which you are making a purchase.
          </p>
          <p>
            <strong>Services</strong>
          </p>
          <p className="no_space">
            Zewst provides technology services to Merchants to enable dining
            customers (“Guests”) to place orders for food, beverages, and
            related products and services provided by the Merchants
            (“Purchases”) and facilitates payment to the Merchants by the Guests
            for those purchases with an eligible Payment Method (“Payment
            Transactions”). Zewst facilitates Purchases with Merchants and
            processes Guests’ payments to Merchants, on behalf of each Merchant.
          </p>
          <p className="subheading">
            <strong>
              Opening and Accessing a Zewst Digital Ordering Account
            </strong>
          </p>
          <p className="subheading no_space">
            When you use Zewst’s Digital Ordering services on a Merchant website
            or premises, you can choose whether you would like to register and
            create a Zewst Digital Ordering Account (hereinafter “Zewst account”
            or “account”). If you create an account, you may save your credit or
            debit card and other personal information to make future orders
            faster and easier. You may make Purchases without creating an
            account, but you will not be able to save any personal information
            or preferences for future Purchases. In order to use the TakeOut
            App, you must download the app to your mobile device and create a
            Zewst account. If you use the same email address for Digital
            Ordering, the TakeOut App, and/or any Merchant loyalty programs, you
            will be registered for one account across those services.
          </p>
          <p className="subheading">
            When you create an account, we will ask you for certain personal
            information such as your name, mobile number, and email address,
            which will be stored with Zewst to facilitate your transactions. You
            may also choose at any time to store Payment Method information,
            which can be used to pay for future Purchases. Information on how we
            collect, use, and protect the personal information you provide to us
            can be found in our Privacy Statement. You agree to provide true,
            accurate current and complete information for your account
            registration, and you agree not to misrepresent your identity or
            your Payment Method information. It is your responsibility to keep
            the information you provide us up-to-date and accurate. Zewst may,
            in its sole discretion, terminate or refuse to approve registrations
            for Zewst Digital Ordering Accounts with or without cause or notice,
            other than any notice required by Applicable Law and Rules. At this
            time, Zewst Services are offered only to users residing in the
            United States and Canada.
          </p>
          <p className="subheading">
            <strong>Confidentiality</strong>
          </p>
          <p className="subheading no_space">
            Only you have the right to access and use your account. You are
            responsible for ensuring that your login information and Payment
            Method information remain confidential at all times. Zewst will
            assume that if your login or Payment Method are used to access the
            Services, the user has the legal authority to use such login or
            Payment Method. If you become aware of unauthorized use of your
            login or Payment Method, you agree to notify Zewst immediately by
            email:{" "}
            <a href="mailto:info@zewst.com" target="_blank">
              info@zewst.com
            </a>
            . You remain liable for any activity on your account until such time
            as Zewst has been notified and has had an opportunity to take
            appropriate action.
          </p>
          <p className="subheading">
            <strong>Zewst is not a Party to Payment Transactions</strong>
          </p>
          <p className="subheading no_space">
            Purchases and Payment Transactions you make through our Services are
            transactions between you and the Merchant only, not with Zewst or
            any of our affiliates. Zewst is not the seller of any product or
            service offered by Merchants and is not a party to any Payment
            Transaction facilitated through the Services.
          </p>
          <p className="subheading">
            <strong>Using your Account</strong>
          </p>
          <p className="subheading no_space">
            Once you have created your account, you will be able to make
            Purchases from Merchants through the Digital Ordering features and
            the TakeOut App. You can make changes to your account information by
            logging in on any Online Ordering page or within the App. You will
            also be able to view your recent Purchase and Payment Transaction
            history by logging in to your account.
          </p>
          <p className="subheading">
            You acknowledge and agree that all aspects of Purchases beyond the
            services that Zewst provides, as well as all engagement and
            interactions between the Merchant and you, are solely the
            responsibility of the Merchant.
          </p>
          <p className="subheading2">
            <strong>Digital Ordering</strong>
          </p>
          <p className="subheading2 no_space">
            Digital Ordering features, including Online Ordering pages and Order
            & Pay at the Table, allow you to make Purchases from a participating
            Merchant using your own computer or mobile device. You can also
            manage your Zewst account from any Online Ordering page by logging
            in. You can see your order history from that Merchant while you are
            logged in.
          </p>
          <p className="subheading2">
            Some Digital Ordering features may allow you to place multiple
            orders as part of one Purchase. When you open a check, you may be
            required to provide payment information prior to completing your
            Purchase. By providing your payment information for
            pre-authorization, you agree to pay the total amount shown when you
            submit the final portion of your order. If you fail to complete
            payment for your Purchase, the Merchant may be able to charge your
            payment method after a certain amount of time has passed.
          </p>
          <p className="subheading2">
            <strong>Zewst Lite App</strong>
          </p>
          <p className="subheading2 no_space">
            It is a mobile application that enables you to make its easier for
            the employees to track their performance and also gives the
            portfolio manager/brand manager ability to quickly login information
            if required.{" "}
          </p>
          <p className="subheading2">
            <strong>Loyalty and Rewards</strong>
          </p>
          <p className="subheading2 no_space">
            Zewst provides technology services that enable Merchants to offer
            loyalty and/or rewards programs to Guests. By enrolling in any
            loyalty program offered by Zewst or by a Merchant using Zewst’s
            technology services, you expressly agree to these program terms.
          </p>
          <p className="subheading2">
            You may enroll in a Merchant’s loyalty program by signing up at the
            Merchant or while placing an order, subject to the Merchant’s terms
            and conditions related to its loyalty program. Once you have
            enrolled, you may earn points when you make Purchases from
            Merchants, which can later be redeemed for discounts or other
            benefits. If you initiate a return, chargeback, or refund, points
            associated with the Purchase may be revoked. You may check your
            current rewards balance by clicking the link you receive when you
            sign up for a Merchant’s loyalty program.
          </p>
          <p className="subheading2">
            If you choose to enroll in a Merchant’s loyalty program, you agree
            that the Merchant may contact you using the contact method(s) you
            provide with program information, offers, and other marketing and
            transactional communications. Any information shared by Zewst with
            Merchants is subject to our Privacy Statement. Once a Merchant has
            received information you provide from Zewst, the use and governance
            of that information will be managed by the Merchant, which may have
            separate privacy practices and policies. Zewst does not authorize
            Merchants to send marketing messages to your mobile number.
            Merchants may not send marketing messages to your mobile number
            unless you expressly authorize the Merchant to send you such
            messages.
          </p>
          <p className="subheading2">
            Each Merchant is solely responsible for the operation and
            maintenance of its own loyalty program, including establishing the
            number of points that you may earn when you make Purchases, setting
            redemption thresholds, and determining how and when points may be
            redeemed for rewards. Merchants have the ability to modify their
            loyalty programs and/or to modify the points balance held by any
            individual Guest. Zewst is not responsible for notifying you in the
            event that the Merchant changes any element of its loyalty program
            or ceases to offer a loyalty program. The Merchant is solely
            responsible for communicating program or points balance changes to
            you.
          </p>
          <p className="subheading2">
            Points earned through any loyalty program offered by Zewst or a
            Merchant using Zewst have no cash value and cannot be transferred,
            redeemed, or sold for cash. Subject to notice requirements under
            Applicable Law and Rules, Zewst reserves the right, in its sole
            discretion and at any time, to modify, suspend, terminate, revalue
            or cancel its own loyalty program and/or the technology services
            that enable Merchants to offer loyalty programs through Zewst. In
            the event Zewst terminates any loyalty program offered by Zewst or
            by a Merchant, Zewst will, in accordance with the Applicable Law and
            Rules, maintain records of Guest’s loyalty points for one year
            following termination of the loyalty program in the event the
            loyalty program is reinstated within one year of termination. Zewst
            reserves the right to adjudicate any discrepancies or disputes
            regarding rewards allocations or these program terms in its sole
            discretion and you agree to abide by any such adjudication.
          </p>
          <p className="subheading">
            <strong>Merchant Responsibilities</strong>
          </p>
          <p className="subheading no_space">
            You hereby acknowledge and understand that each Merchant is solely
            responsible for all aspects of its own day-to-day operations,
            including provision of food and beverages (by any method, including
            through delivery), service, environment, and overall quality and
            accuracy.
          </p>
          <p className="subheading2">
            <strong>Compliance</strong>
          </p>
          <p className="subheading2 no_space">
            You hereby acknowledge and understand that each Merchant is solely
            responsible and liable for all marketing, selling, pricing,
            packaging, and provision of any products or services offered to
            Guests through the Services in compliance with all Applicable Law
            and Rules. Zewst makes no representation or warranty regarding
            whether a Merchant holds any applicable permit, license,
            registration, or other credential for its business; whether
            representations by a Merchant are true and accurate; or whether a
            Merchant complies with Applicable Law and Rules, and Zewst is not
            responsible for the quality of the products or services provided by
            Merchants.
          </p>
          <p className="subheading2">
            <strong>Alcoholic Beverages</strong>
          </p>
          <p className="subheading2 no_space">
            Certain Merchants may hold alcoholic beverage licenses and sell
            alcoholic beverage products. The Merchant holding the alcoholic
            beverage license must approve your Purchase before the Purchase will
            be finalized, and only upon acceptance of the order by the Merchant
            will your Payment Method be charged. The portion of the funds
            charged to your Payment Method which pertains to your alcoholic
            beverage product Purchase may be held in an account for the benefit
            of the alcoholic beverage supplier in compliance with Applicable Law
            and Rules. Zewst acts as a third-party technology provider to and
            facilitates payment processing on behalf of the Merchant, and has no
            responsibility or liability to you or any other person for any
            alcoholic beverage products you may purchase from a Merchant or for
            any Merchant’s compliance with Applicable Law and Rules, including
            without limitation, local regulations regarding sale of alcohol. By
            making a Purchase through the Services for alcoholic beverage
            products, you represent and warrant that (i) if you are a U.S.
            consumer attempting to consume alcoholic beverages in the U.S., you
            are twenty-one (21) years of age or older and, if you are a Canadian
            consumer attempting to consume alcoholic beverages in Ontario and
            British Columbia, you are nineteen (19) years of age or older, (ii)
            you are not procuring alcoholic beverage products for a person under
            twenty-one (21) years of age in the U.S. or under nineteen (19)
            years of age in Ontario or British Columbia and, (iii) you will
            provide bona fide government issued photo identification evidencing
            your age.
          </p>
          <p className="subheading">
            <strong>Closing your Account</strong>
          </p>
          <p className="subheading no_space">
            You may close your Zewst account at any time and without cost, but
            you will remain liable for any outstanding Purchases as well as any
            fees or other charges incurred. Zewst will not issue refunds for
            amounts previously incurred through our Services once you close your
            account.
          </p>
          <p className="subheading">
            Guests can close their accounts by emailing to info@zewst.com Please
            review our Privacy Statement for further information about our
            practices regarding your personal information.
          </p>
          <p className="subheading">
            In certain cases, we may not allow you to close your account,
            including but not limited to:
          </p>
          <ul className="list1 subheading">
            <li>
              <p>to evade an investigation;</p>
            </li>
            <li>
              <p>
                you have open or pending Purchases or Payment Transactions; or
              </p>
            </li>
            <li>
              <p>
                if you owe money to Zewst or a Merchant due to your use of the
                Services.
              </p>
            </li>
          </ul>
          <p>
            <strong>Data Privacy and Security</strong>
          </p>
          <p className="no_space">
            The privacy and security of your personal information is important
            to us. Zewst’s Privacy Statement describes what information we
            collect about you, how we may use personal information and the
            security measures we have taken to protect your personal
            information. We encourage you to read the Privacy Statement
            carefully, as it forms a binding part of these Terms of Service and
            contains important information about your rights.
          </p>
          <p>
            <strong>Purchases</strong>
          </p>
          <p className="no_space">
            Zewst makes available the Services and, either directly or through a
            third party service provider, processes Payment Transactions for
            Purchases on behalf of Merchants as the agent of the Merchants
            through the payment networks. When you make a Purchase, you
            authorize Zewst, as agent for the Merchant, to submit the charge in
            the amount of the Purchase (including any gratuities, fees and
            taxes) to your Payment Method as well as any credits in connection
            with chargebacks, reversals, refunds or adjustments. Zewst, as the
            agent of the Merchant, will assist the Merchant in submitting the
            Payment Transaction to the payment network and processing the
            Payment Transaction. Upon Zewst receiving the proceeds of the
            transaction, your payment obligation to the Merchant will be deemed
            completed (except in the case of a later chargeback or reversal).
          </p>
          <p className="">
            You must provide a valid Payment Method to pay for your Purchases
            made through any of the Services. You authorize Zewst to confirm
            your Payment Method is in good standing with the issuing financial
            institution. In order to do so, Zewst may obtain an authorization to
            charge your Payment Method for a Payment Transaction. This
            authorization may reduce your available funds balance by the
            authorization amount until the Payment Transaction is actually
            charged to your Payment Method and the proceeds are processed and
            settled. Please contact your Payment Method issuer if you have
            questions regarding the status of an authorization or of a charge to
            your Payment Method. You agree that Zewst, on behalf of the
            Merchant, may resubmit a Payment Transaction for processing to a
            payment network in the event a prior Payment Transaction was
            declined or returned.
          </p>
          <p className="">
            If you choose to store Payment Method information with Zewst via any
            of the Services for your convenience and use in future transactions,
            you agree that Zewst may receive up-to-date information on your
            Payment Method information (such as card number or expiration date)
            through services available from the card networks and may update
            your Payment Method credentials stored with Zewst (if you choose to
            store your Payment Method credentials) from time to time.
          </p>
          <p className="">
            We may establish limitations concerning use of the Services,
            including without limitation individual or aggregate transaction
            limits on the dollar amount or number of Payment Transactions you
            may make within certain time periods. We may decline to process any
            Payment Transaction without any notice to you. We may delay
            processing of or hold or cancel processing of any Payment
            Transaction upon the direction of the Merchant or if we believe, in
            our sole discretion, that the transaction is invalid, suspicious,
            involves misconduct or fraud, or otherwise violates Applicable Law
            and Rules, these Terms of Service, or any other policies.
          </p>
          <p className="">
            Payments made through the Services are also subject to the terms of
            your agreement with your Payment Method issuer. You are solely
            responsible for any charges or fees that may be imposed by your
            Payment Method issuer as a result of using the Services.
          </p>
          <p>
            <strong>Permitted Activities</strong>
          </p>
          <p className="no_space">By using the Services, you agree that:</p>
          <ul className="list1">
            <li>
              <p>
                You will not use the Services in any way that violates
                Applicable Law and Rules, these Terms, or any other policies.
              </p>
            </li>
            <li>
              <p>
                You may only use the Services to make Purchases of a legitimate,
                bona fide product or service that is purchased from a Merchant.
                The Services may not be used to transfer money or process a
                Payment Transaction that is unrelated to a purchase of a product
                or service from a Merchant.
              </p>
            </li>
            <li>
              <p>
                The information that you upload or post in connection with the
                Services does not infringe on anyone else's intellectual
                property or proprietary rights or otherwise conflict with the
                law or the rights of others.
              </p>
            </li>
            <li>
              <p>
                You will not upload, post or otherwise transmit through the
                Services any content that contains any viruses, trojan horses,
                time bombs, or any other harmful programs or elements.
              </p>
            </li>
            <li>
              <p>
                You will not provide false information about yourself to us,
                impersonate any other person, collect information about other
                users, or otherwise attempt to mislead others about your
                identity or the truthfulness or accuracy of the data you
                transmit through the Services.
              </p>
            </li>
            <li>
              <p>
                You will not damage, disable, disrupt, overburden, interfere
                with, or attempt to gain unauthorized access to any portion of
                our Services, computer systems, servers or networks, or any
                other person’s use and enjoyment of the Services.
              </p>
            </li>
            <li>
              <p>
                Zewst may suspend or terminate your use of the Services at its
                sole discretion for any actual or suspected violation of these
                limitations or other applicable policies or rules referenced in
                these Terms of Service.
              </p>
            </li>
          </ul>
          <p>
            <strong>Fees</strong>
          </p>
          <p className="no_space">
            As of the date of these Terms, Zewst does not charge you a fee to
            make Purchases from Merchants using Zewst Services. We reserve the
            right to charge fees in the future, and any fees applicable to a
            Purchase or Payment Transaction you make will be clearly disclosed
            to you prior to the completion of your Purchase.
          </p>
          <p>
            Merchants may charge you fees as well as taxes in addition to the
            price of the products or services you purchase. You may also be
            permitted to provide a gratuity. By making a Purchase through the
            Services, you agree to pay all such charges, including gratuities,
            fees and taxes. All amounts to be charged to your Payment Method
            associated with your Purchase will be displayed to you before you
            complete your Purchase.
          </p>
          <p>
            <strong>
              Order Changes, Cancellation, Refunds and Customer Service
            </strong>
          </p>
          <p className="no_space">
            Merchants each set their own cancellation and refund policies. Once
            a Purchase has been made through the TakeOut App, any Digital
            Ordering feature, or a Point of Sale feature, you will need to
            contact the Merchant directly to inquire if you can make a change to
            or cancel the Purchase or whether you may receive a refund.
          </p>
          <p>
            For questions or customer service regarding a Purchase or the
            product or service provided by a Merchant, please contact the
            Merchant directly. For the TakeOut App, each Merchant’s contact
            information for customer service is available on its App listing.
            Zewst is not responsible for any Merchant service issues or content
            errors or inaccuracies related to a Merchant’s website, menu, or
            other materials.
          </p>
          <p>
            For technical questions that relate to your use of Zewst app, please
            email{" "}
            <a href="mailto:info@zewst.com" target="_blank">
              info@zewst.com
            </a>
          </p>
          <p>
            <strong>Promotions</strong>
          </p>
          <p className="no_space">
            When you use the Zewst Services, you may be eligible to use codes
            (“Promotion Codes”) at checkout to receive discounts or other
            promotional offers on purchases (“Promotions”), subject to any terms
            and conditions of the specific Promotion Code or Promotion, as
            displayed on our Websites, on the App, or related print, social
            media, or digital marketing materials. The promotional value may be
            issued by Zewst or by a Merchant. Promotion Codes may not be
            redeemed for cash and are non-refundable and non-transferrable.
            Zewst reserves the right to modify, suspend, terminate, or alter the
            terms of any Promotion Code or Promotion at any time. Promotion
            codes may not be reused in the event of a cancellation and/or
            refund.
          </p>
          <p>
            <strong>
              Disclaimer of Warranties, Waiver and Limitation of Liability
            </strong>
          </p>
          <p className="subheading">
            <strong>DISCLAIMER OF WARRANTIES</strong>
          </p>
          <p className="subheading no_space">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND
            ALL INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD
            PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICE IS AT YOUR SOLE
            RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
            BASIS.
          </p>
          <p className="subheading">
            NEITHER ZEWST NOR ITS THIRD-PARTY PROVIDERS WILL BE LIABLE OR
            RESPONSIBLE FOR ANY PRODUCTS OR SERVICES PROVIDED BY MERCHANTS THAT
            ARE A CAUSE OF INJURY OR THAT ARE UNACCEPTABLE OR DO NOT MEET YOUR
            REQUIREMENTS OR EXPECTATIONS.
          </p>
          <p className="subheading">
            EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH HEREIN, ZEWST AND ITS
            THIRD-PARTY PROVIDERS HEREBY EXPRESSLY DISCLAIM ALL EXPRESS OR
            IMPLIED WARRANTIES WITH REGARD TO THE SERVICES AND ALL INFORMATION,
            PRODUCTS AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES)
            INCLUDED IN OR ACCESSIBLE FROM THE SERVICES, INCLUDING BUT NOT
            LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, NON- INFRINGEMENT AND QUALITY. ZEWST
            RELIES UPON MerchantS TO PROVIDE ACCURATE ALLERGEN AND DIETARY
            INFORMATION AND GENERAL PRODUCT SAFETY. ZEWST DOES NOT REPRESENT OR
            WARRANT THAT THE INFORMATION ACCESSIBLE THROUGH THE SERVICES IS
            ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE, INCLUDING,
            WITHOUT LIMITATION, MENUS, NUTRITIONAL AND ALLERGEN INFORMATION,
            PHOTOS, FOOD QUALITY OR DESCRIPTIONS, PRICING, HOURS OF OPERATION,
            OR REVIEWS. ALL CONTENT IS PROVIDED FOR INFORMATIONAL PURPOSES ONLY.
            THE RELIANCE ON ANY INFORMATION PROVIDED THROUGH THE SERVICE IS
            SOLELY AT YOUR OWN RISK, INCLUDING, WITHOUT LIMITATION, NUTRITIONAL
            AND ALLERGEN INFORMATION.
          </p>
          <p className="subheading">
            ZEWST AND ITS THIRD-PARTY PROVIDERS MAKE NO REPRESENTATIONS OR
            WARRANTIES REGARDING (I) WHETHER THE SERVICES WILL MEET YOUR
            REQUIREMENTS; (II) THE RELIABILITY, AVAILABILITY, TIMELINESS,
            SUITABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES; (III) THE
            RESULTS YOU MAY OBTAIN BY USING THE SERVICES; (IV) WHETHER THE
            OPERATION OR USE OF THE SERVICES WILL BE UNINTERRUPTED OR
            ERROR-FREE; OR (V) WHETHER THE QUALITY OF THE SERVICE, OR PRODUCTS
            OR SERVICE, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED
            THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS.
          </p>
          <p className="subheading">
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY
            RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR DEVICE OR LOSS
            OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO
            ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            ZEWST OR A THIRD PARTY THROUGH OR FROM THE SERVICES WILL CREATE ANY
            WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
          </p>
          <p className="subheading">
            YOU ACKNOWLEDGE THAT NEITHER ZEWST NOR ITS THIRD-PARTY PROVIDERS
            CONTROLS THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES,
            INCLUDING THE INTERNET, AND THAT NEITHER ZEWST NOR ITS THIRD-PARTY
            PROVIDERS ARE RESPONSIBLE FOR ANY LIMITATIONS, DELAYS, OR OTHER
            PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES.
            WITHOUT LIMITING THE FOREGOING, NEITHER ZEWST NOR ITS THIRD-PARTY
            PROVIDERS WARRANTS OR GUARANTEES THAT ANY OR ALL SECURITY BREACHES
            OR ATTACKS WILL BE DISCOVERED, REPORTED OR REMEDIED, OR THAT THERE
            WILL NOT BE ANY SECURITY BREACHES BY THIRD PARTIES.
          </p>
          <p className="subheading">
            <strong>Waiver of Liability</strong>
          </p>
          <p className="subheading no_space">
            You acknowledge and agree that the Merchant is the seller of the
            food, beverages and related products and services which you may
            order and pay for through TakeOut App. The Merchant is solely
            responsible for any and all damages, claims, liabilities, costs,
            injuries or illness caused in whole or in part by the Merchant.
            Merchant is also solely responsible for any unclaimed property
            liability which may arise from Purchases, including gift cards, paid
            for but not received by you.
          </p>
          <p className="subheading">
            <strong>LIMITATION OF LIABILITY</strong>
          </p>
          <p className="subheading no_space">
            Some states do not allow exclusion of implied warranties or
            limitation of liability for incidental or consequential damages, so
            the above limitations or exclusions may not apply to you. IN SUCH
            STATES, ZEWST’S LIABILITY OF WILL BE LIMITED TO THE MAXIMUM EXTENT
            PERMITTED BY LAW.
          </p>
          <p className="subheading">
            <strong>Reliance on Limitations</strong>
          </p>
          <p className="subheading no_space">
            Each party acknowledges that the other party has entered into these
            Terms relying on the limitations of liability stated herein and that
            those limitations are an essential basis of the bargain between the
            parties.
          </p>
          <p>
            <strong>Force Majeure</strong>
          </p>
          <p className="no_space">
            Zewst will not be liable for any failure or delay resulting from any
            condition beyond its reasonable control, including but not limited
            to governmental action or acts of terrorism, earthquake, fire,
            pandemic, flood or other acts of God, labor conditions, power
            failures, and Internet disturbances.
          </p>
          <p>
            <strong>Indemnification</strong>
          </p>
          <p className="no_space">
            You agree to defend, indemnify and hold harmless Zewst and its
            directors, officers, employees, affiliates and agents from and
            against any and all third party claims, liability, damages, expenses
            and costs actions or demands, including, without limitation,
            reasonable legal and accounting fees, arising or resulting from (a)
            your breach of these Terms or any other policy; (b) your access to,
            use, or misuse of the Third-Party Content or Services; and (c) your
            infringement or infringement by any other user of your account, of
            any intellectual property or other right of any other person. Zewst
            will provide notice to you of any such claim, suit, or proceeding.
            Zewst reserves the right to assume the exclusive defense and control
            of any matter which is subject to indemnification under this
            Section. In such case, you agree to cooperate with any reasonable
            requests assisting Zewst’s defense of such matter.
          </p>
          <p>
            <strong>Ownership of Content and Use of Trademarks</strong>
          </p>
          <p className="subheading">
            <strong>Ownership</strong>
          </p>
          <p className="subheading no_space">
            Zewst owns or has license to all rights, title, interest, copyright
            and other worldwide intellectual property and trade secret rights in
            and to the Services (including all derivatives or improvements
            thereof). You may voluntarily submit suggestions, enhancement
            requests, ideas, feedback, recommendations or other input about the
            Services (“Feedback”) at any time. You irrevocably assign all right,
            title, interest and other worldwide intellectual property rights in
            and to the Feedback to Zewst, Inc., and acknowledge that we are free
            to use, disclose, reproduce and otherwise exploit any and all
            Feedback provided by you relating to the Services in our sole
            discretion, entirely without obligation or restriction of any kind.
            Any rights not expressly granted herein are reserved by Zewst.
          </p>
          <p className="subheading">
            <strong>User Content</strong>
          </p>
          <p className="subheading no_space">
            You retain all rights, title and interest in and to any text,
            graphics, videos, images or other data that you upload to the
            Services (“User Content”). You grant to Zewst a non-exclusive,
            royalty-free, fully paid-up, worldwide license to access, use, copy,
            modify (including the right to create derivative works of), display
            and transmit User Content for the purpose of our providing the
            Services and in accordance with our Privacy Statement. You are
            solely responsible for the accuracy, quality, content, and legality
            of User Content, the means by which User Content is acquired, and
            any transfer of User Content outside of the Services by you or any
            third-party authorized by you. You represent, warrant and covenant
            that you have all rights necessary to upload the User Content to the
            Services and to otherwise have such User Content used or shared, as
            applicable, in relation to the Services.
          </p>
          <p className="subheading">
            <strong>Third-Party Content</strong>
          </p>
          <p className="subheading no_space">
            Through your use of the Services you may be presented with material
            provided by third parties, not owned or controlled by us, from our
            partners, and/or from other users of the Services, including but not
            limited to links to websites or other materials, software, text,
            graphics, videos, images, or advertising content (collectively
            referred to as “Third-Party Content”). All Third-Party Content and
            the Services are protected by United States and foreign intellectual
            property laws. Unauthorized use of the Services and/or Third-Party
            Content may result in violation of copyright, trademark, and other
            laws. Except as expressly provided herein, you have no rights in or
            to the Services or Third-Party Content, and you will not use, copy
            or display the Services or Third-Party Content except as permitted
            under these Terms. No other use of the Services or Third-Party
            Content is permitted without our prior written consent. You must
            retain all copyright and other proprietary notices contained in the
            Services and Third-Party Content. You may not sell, transfer,
            assign, license, sublicense, or modify the Third-Party Content or
            reproduce, display, publicly perform, make a derivative version of,
            distribute, or otherwise use the Third-Party Content in any way for
            any public or commercial purpose other than as permitted hereunder.
            The use or posting of any of the Third-Party Content on any other
            platform, or in a networked computer environment for any purpose is
            expressly prohibited. If you violate any part of these Terms of
            Service, your right to access and/or use the Third-Party Content and
            Services will automatically terminate.
          </p>
          <p className="subheading">
            <strong>Monitoring and Removal of Content</strong>
          </p>
          <p className="subheading no_space">
            We do not review, pre-screen or filter all User Content, or
            Third-Party Content, but we do reserve the right to delete or refuse
            to accept any User Content or Third-Party Content in our sole
            discretion. In addition, we have the right (but not the obligation)
            in our sole discretion to reject or delete any content that we
            reasonably consider to be in violation of these Terms or Applicable
            Law and Rules.
          </p>
          <p className="subheading">
            <strong>Third-Party Content Precautions</strong>
          </p>
          <p className="subheading no_space">
            We do not guarantee the accuracy, integrity or quality of any
            Third-Party Content, regardless of whether such products or services
            are designated as “certified,” “validated” or the like. Any
            interaction or exchange of information or data between you and any
            third-party is solely between you and such third-party. You should
            take precautions when downloading files from any platform to protect
            your computer from viruses and other destructive programs. If you
            decide to access any Third-Party Content, you fully assume the risk
            of doing so. Under no circumstances will Zewst be liable in any way
            for any Third-Party Content, including liability for any errors or
            omissions in any Third-Party Content or for any loss or damage of
            any kind incurred as a result of the use of or reliance on any
            Third-Party Content posted, emailed, linked or otherwise transmitted
            via the Services.
          </p>
          <p className="subheading">
            <strong>Trademarks</strong>
          </p>
          <p className="subheading no_space">
            The trademarks, service marks, and logos of Zewst (the “Zewst
            Trademarks”) used and displayed on the Services are registered and
            unregistered trademarks or service marks of Zewst. Other Zewst
            product and service names located in the Services may be trademarks
            or service marks owned by a Merchant or other third-parties (the
            “Third-Party Trademarks”, and, collectively with the Zewst
            Trademarks, the “Trademarks”). Nothing in these Terms of Service
            should be construed as granting, by implication, estoppel, or
            otherwise, any license or right to use any Trademark displayed in
            the Services without the prior written consent of Zewst specific for
            each such use. The Trademarks may not be used to disparage Zewst or
            the applicable third-party, Zewst’s or third-party’s products or
            services, or in any manner that may damage any goodwill in the
            Trademarks. Except as described herein, the use of any Trademarks is
            prohibited without Zewst’s prior written consent. All goodwill
            generated from the use of any Zewst Trademark or Third-Party
            Trademark will inure to Zewst’s, or the applicable Third Party’s
            benefit, as applicable.
          </p>
          <p>
            <strong>Termination of the Terms of Service</strong>
          </p>
          <p className="no_space">
            These Terms of Service continue to govern your use of the Services
            unless and until such time as you or we terminate your use of any
            applicable Service(s). Upon termination, you will lose access to the
            relevant Services, and we may delete any information stored
            regarding you or your transactions through the Services. Termination
            of your use of any of the Services does not relieve you of the
            obligation to pay for any Purchases made as well as related fees and
            charges already incurred. In the event of termination of your use of
            the Services by you or us, we will not provide any refunds for
            amounts previously paid through the Services.
          </p>
          <p>
            Zewst reserves the right, in its sole discretion, to limit, suspend,
            or terminate your Zewst account and/or access to all or any part of
            the Services at any time and for any reason without notice to you
            and without liability to you or to any third party. Additionally,
            any of the Services, or any feature of portion thereof, may be
            modified, replaced, suspended, or terminated, without or notice at
            any time, in Zewst’s sole discretion, without liability.
          </p>
          <p>
            <strong>
              Electronic Communications (E-Sign Disclosure and Consent)
            </strong>
          </p>
          <p className="no_space">
            These terms describe how Zewst delivers communications to you
            electronically. We may amend these terms from time to time, and if
            we make substantial changes, we will provide you with reasonable
            notice in accordance with any communication preferences you have
            given to us, and by posting notice of the updates on our Website.
          </p>
          <p className="subheading">
            <strong>Consent for Electronic Communications</strong>
          </p>
          <p className="subheading no_space">
            You agree and consent to receive electronically all communications,
            agreements, disclosures, notices and documents (collectively
            “Communications”) that we provide in connection with your Zewst
            account and the Services we provide. We may at times be required to
            provide you with Communications in written form, which you agree to
            receive electronically instead of in paper form, including by
            sending via electronic mail to the email address you provide to us,
            or by posting notice or communicating with you via the Zewst app or
            our website. Communications include these Terms and any and all
            other agreements or policies to which you must agree in order to use
            the Services, including updates to those agreements and policies;
            payment authorizations and transaction receipts or confirmations
            customer service matters; and any other communication related to
            your use of the Services.
          </p>
          <p className="subheading">
            It is your responsibility to keep your email address accurate and up
            to date so that Zewst can communicate with you. You can update the
            email address associated with your Zewst account by logging in and
            accessing the Profile section within the Zewst app or any Online
            Ordering page.
          </p>
          <p className="subheading">
            All Communications sent in electronic format will be considered to
            be in “writing and are considered received by you upon posting
            through the Website or Zewst app, or sending to you via electronic
            email or SMS, regardless of whether you have accessed that
            communication.
          </p>
          <p className="subheading">
            <strong>Consent to Contact Mobile Number</strong>
          </p>
          <p className="subheading no_space">
            By voluntarily providing your mobile phone number to Zewst, you
            confirm you are authorized to provide that number to Zewst and agree
            that Zewst may contact you at that number. If you provide a mobile
            number, you expressly agree that Zewst may contact you using
            automated telephone call, and SMS or MMS messages at that phone
            number, and you hereby consent to receiving such communications for
            transactional, informational, and operational purposes.
          </p>
          <p className="subheading">
            <strong>Term of Consent</strong>
          </p>
          <p className="subheading no_space">
            Your consent to receive electronic Communications is valid until and
            unless you revoke it. You may revoke your consent at any time,
            however, consent to electronic Communications is a condition of some
            of our Services, and if you revoke it you will no longer be
            permitted to use the Services. If you wish to revoke your consent
            for electronic Communications, please contact{" "}
            <a href="mailto:info@zewst.com" target="_blank">
              info@zewst.com
            </a>
          </p>
          <p className="subheading">
            <strong>Methods of Agreement</strong>
          </p>
          <p className="subheading no_space">
            You acknowledge and agree that by clicking on the “I Agree”,
            “Submit”, “Create Account” or similar button on the website, Online
            Ordering page or other feature offered under our Services and
            associated with these Terms or with any other electronic document
            authorizing us to provide the Services to you, you are indicating
            your intent to sign these Terms and/or other applicable
            agreement(s). You also agree that clicking on these buttons on the
            website, Digital Ordering feature App shall constitute your
            electronic signature to these Terms or other such document displayed
            with the button(s) and relating to the Services. You further agree
            that your electronic signature on these Terms and any other document
            shall have the same legal effect under state and federal law as if
            you signed the documents in ink on paper.
          </p>

          <p>
            <strong>Arbitration</strong>
          </p>
          <p className="subheading">
            <strong>Agreement to Arbitrate</strong>
          </p>
          <p className="subheading no_space">
            This Section 14 is referred to as the Arbitration Agreement. You
            agree that any and all disputes or claims that have arisen or may
            arise between you and Zewst, whether arising out of or relating to
            these Terms of Service or in connection with your use of the
            Services, shall be resolved exclusively through final and binding
            arbitration, rather than a court, in accordance with the terms of
            this Arbitration Agreement, except that you may assert individual
            claims in small claims court, if your claims qualify. You agree
            that, by agreeing to these Terms, you and Zewst are each waiving the
            right to a trial by jury or to participate in a class action. Your
            rights will be determined by a neutral arbitrator, not a judge or
            jury. The Federal Arbitration Act governs the interpretation and
            enforcement of this Arbitration Agreement. Notwithstanding the
            foregoing, this Arbitration Agreement shall not preclude either
            party from pursuing a court action for the sole purpose of obtaining
            a temporary restraining order or preliminary injunction in
            circumstances in which such relief is appropriate, provided that any
            other relief shall be pursued through an arbitration proceeding
            pursuant to this Arbitration Agreement.
          </p>
          <p className="subheading">
            <strong>
              Prohibition of Class and Representative Actions and
              Non-Individualized Relief
            </strong>
          </p>
          <p className="subheading no_space">
            You and Zewst agree that each may bring claims against the other
            only on an individual basis and not as plaintiff or class member in
            any purported class or representative action or proceeding. Unless
            both you and Zewst agree otherwise, the arbitrator may not
            consolidate or join more than one person’s or party’s claims and may
            not otherwise preside over any form of a consolidated,
            representative, or class proceeding. Also, the arbitrator may award
            relief (including monetary, injunctive, and declaratory relief) only
            in favor of the individual party seeking relief and only to the
            extent necessary to provide relief necessitated by that party’s
            individual claim(s).
          </p>
          <p className="subheading">
            <strong>Pre-Arbitration Dispute Resolution</strong>
          </p>
          <p className="subheading no_space">
            Most concerns can be resolved quickly and to all parties’
            satisfaction by emailing Zewst’s Customer Care team at
            support@Zewsttab.com. If such efforts prove unsuccessful, a party
            who intends to seek arbitration must first send to the other, by
            certified mail, a written Notice of Dispute (“Notice”). The Notice
            to Zewst should be sent to Zewst at 401 Park Drive, Suite 801,
            Boston, MA 02215, Attn: General Counsel. The Notice must (i)
            describe the nature and basis of the claim or dispute and (ii) set
            forth the specific relief sought. If you and Zewst do not resolve
            the claim within sixty (60) calendar days after the Notice is
            received, you or Zewst may commence an arbitration proceeding.
            During the arbitration, the amount of any settlement offer made by
            Zewst or you shall not be disclosed to the arbitrator until after
            the arbitrator determines the amount, if any, to which you or Zewst
            is entitled.
          </p>
          <p className="subheading">
            <strong>Confidentiality</strong>
          </p>
          <p className="subheading no_space">
            All aspects of the arbitration proceeding, and any ruling, decision,
            or award by the arbitrator, will be strictly confidential for the
            benefit of all parties.
          </p>
          <p className="subheading">
            <strong>Severability</strong>
          </p>
          <p className="subheading no_space">
            If a court or the arbitrator decides that any term or provision of
            this Arbitration Agreement other than Sub-Section 14.1 above is
            invalid or unenforceable, the parties agree to replace such term or
            provision with a term or provision that is valid and enforceable and
            that comes closest to expressing the intention of the invalid or
            unenforceable term or provision, and this Arbitration Agreement
            shall be enforceable as so modified. If a court or the arbitrator
            decides that any of the provisions of Sub-Section 14.1 is invalid or
            unenforceable, then the entirety of this Arbitration Agreement shall
            be null and void. The remainder of these Terms will continue to
            apply.
          </p>

          <p>
            <strong>Survival</strong>
          </p>
          <p className="no_space">
            Upon termination of your use of the Service or these Terms for any
            reason, in addition to this section, the following sections shall
            survive termination: Section 1.7 (Merchant Responsibilities), 5
            (Fees), 8 (Disclaimer of Warranties, Waiver and Limitation of
            Liability), 10 (Indemnification), 11 (Ownership of Content, Use of
            Trademarks), 12 (Termination), 14 (Arbitration), 17 (Governing Law),
            and 18 (Miscellaneous).
          </p>

          <p>
            <strong>Assignment</strong>
          </p>
          <p className="no_space">
            You may not assign these Terms or any rights or obligations
            hereunder, by operation of law or otherwise, without our prior
            written consent and any attempted assignment may be void. We reserve
            the right to freely assign these Terms and the rights and
            obligations hereunder, to any third party without notice and
            consent. Subject to the foregoing, these Terms shall be binding upon
            and inure to the benefit of the parties hereto, their successors and
            permitted assigns.
          </p>

          <p>
            <strong>Governing Law</strong>
          </p>
          <p className="no_space">
            Any action, claim, or dispute related to these Terms of Service will
            be governed by the laws of the Commonwealth of Massachusetts,
            excluding its conflicts of law provisions, and controlling U.S.
            federal law. The United Nations Convention on Contracts for the
            International Sale of Goods and Uniform Computer Information
            Transactions Act will not apply to these Terms of Service.
          </p>

          <p>
            <strong>Miscellaneous</strong>
          </p>
          <p className="no_space">
            If any provision of these Terms is found to be invalid by any court
            having competent jurisdiction, the invalidity of such provision will
            not affect the validity of the remaining provisions of these Terms,
            which will remain in full force and effect. Failure of Zewst to act
            on or enforce any provision of these Terms will not be construed as
            a waiver of that provision or any other provision herein. No waiver
            will be effective against Zewst unless made in writing, and no such
            waiver will be construed as a waiver in any other or subsequent
            instance. Except as expressly agreed by Zewst and you, these Terms,
            including any Zewst policies governing the Services referenced
            herein, constitute the entire agreement between you and Zewst with
            respect to the subject matter hereof, and supersedes all previous or
            contemporaneous agreements, whether written or oral, between you and
            Zewst with respect to the subject matter. The section headings are
            provided merely for convenience and will not be given any legal
            import.
          </p>

          <Link to="/" className="button" color="black-border">
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default TermsConditions;

import SectionWelcome from "../../components/sections/welcome";
import SectionSmartPos from "../../components/sections/smartpos";
import SectionFoodWaste from "../../components/sections/foodwaste";
import SectionInventory from "../../components/sections/inventory";
import SectionForecast from "../../components/sections/forecast";
import SectionRecipe from "../../components/sections/recipe";

function Home(props) {
  const { handleModal } = props;
  // const windowDimensions = useWindowDimensions();
  // const sectionHeight = windowDimensions.height;

  // useEffect(() => {
  //     window.addEventListener('scroll', isSticky);
  //     return () => {
  //         window.removeEventListener('scroll', isSticky);
  //     };
  // });

  // const isSticky = (e) => {
  //     const topSection = document.querySelector('.topSection');
  //     const scrollTop = window.scrollY;
  //     scrollTop > 1 && scrollTop < 30 ? topSection.style.height = sectionHeight - scrollTop : topSection.style.height = sectionHeight - 79;
  // };

  return (
    <>
      <SectionWelcome handleModal={handleModal} />
      <SectionSmartPos />
      <SectionFoodWaste handleModal={handleModal} />
      <SectionInventory handleModal={handleModal} />
      <SectionForecast handleModal={handleModal} />
      <SectionRecipe handleModal={handleModal} />
    </>
  );
}

export default Home;

import { Link } from "react-router-dom";
import Icons from "../../components/icons";

function Subscriptions() {
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>Subscriptions</h2>
          <h4 className="centered">
            <strong>Plans for every restaurant</strong>
          </h4>
          <p className="no_space">
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to name, phone, age, facial. The
            information that we request will be retained by us and used as
            described in the privacy policy.
          </p>
          <div className="card_section">
            <div className="card_subscription">
              <div className="card_header">
                <p className="card_heading">Free</p>
              </div>
              <div className="card_body">
                <p className="card_content">
                  Ideal for single-location restaurants who need 1-2 terminals
                  to get up and running.
                </p>
                <p className="card_starting">Starting At</p>
                <p className="card_price no_space">
                  0$<span className="card_per_month"> /month</span>
                </p>
              </div>
              <div className="card_footer">
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Zewst Launch</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Zewst Online Lite</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Rewards</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Food Waste</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Menu Engineering</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Recipe Engineering</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>POS</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>KDS</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Delivery App Integration</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>Zewst Online</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>Franchising</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>AI Sales Prediction</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>AI Food Waste Prediction</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>Inventory Management</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>Marketing</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>SmartCam</span>
                  </p>
                </div>
                <div className="">
                  <p className="card_features">
                    <Icons className="card_cross" closeModal />
                    <span>AI Recommendations across POS and Online</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="card_subscription">
              <div className="card_header">
                <p className="card_heading">Essential</p>
              </div>
              <div className="card_body">
                <p className="card_content">
                  Ideal for restaurants looking to grow sales by opening more
                  branches and managing multiple cloud kitchen
                </p>
                <p className="card_starting">Starting At</p>
                <p className="card_price no_space">
                  100$<span className="card_per_month"> /month</span>
                </p>
              </div>
              <div className="card_footer">
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Zewst Launch</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Zewst Online Lite</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Rewards</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Food Waste</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Menu Engineering</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Recipe Engineering</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>POS</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>KDS</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Delivery App Integration</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Zewst Online</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Franchising</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>AI Sales Prediction</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>AI Food Waste Prediction</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Inventory Management</span>
                  </p>
                </div>
                <div className="display-flex">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>Marketing</span>
                  </p>
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>SmartCam</span>
                  </p>
                </div>
                <div className="">
                  <p className="card_features">
                    <Icons className="card_check" checklist1 />
                    <span>AI Recommendations across POS and Online</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Link
            style={{ margin: "auto" }}
            to="/"
            className="button"
            color="black-border"
          >
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default Subscriptions;

import { Link } from "react-router-dom";

function RefundPolicy() {
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>Refund Policy</h2>
          <p className="no_space">
            We believe in all our products and stand by their quality and
            performance. However, things might not work in the same way for
            everyone. Therefore, if you come across an issue that cannot be
            resolved, or feel that any of our products are not working as
            expected, you can contact us for a refund{" "}
            <strong>within 14 days after purchase</strong>. We’ll be happy to
            refund partially depending on the case.
          </p>
          <p>
            Our Team will look for the possibility to fix the issues before
            issuing the refund. In some cases, we might even offer to resolve an
            issue for you.
          </p>
          <p>
            <strong>
              Your refund requests must meet all of the following conditions in
              order to move forward to the next step:
            </strong>
          </p>
          <ul className="list1">
            <li>
              <p>
                No refunds will be processed after the first 14 days of
                purchase.{" "}
              </p>
            </li>
            <li>
              <p>
                After purchasing, installing, and testing the software, you have
                found that it does not work for your business or required setup.
              </p>
            </li>
            <li>
              <p>
                You have attempted to resolve your issues with our support team.
              </p>
            </li>
            <li>
              <p>
                By requesting and receiving a refund, you relinquish all
                ownership of your license and agree to cease the use of the
                product immediately.
              </p>
            </li>
            <li>
              <p>
                We reserve the right to reject a refund request if deemed
                necessary.
              </p>
            </li>
            <li>
              <p>
                You can submit a refund request by opening a support ticket.
              </p>
            </li>
          </ul>

          <Link to="/" className="button" color="black-border">
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default RefundPolicy;

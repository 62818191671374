import { Link } from 'react-router-dom';
import styles from './index.module.css';
import Icons from '../icons';
import ImgCookie from './../../assets/images/cookie.png';

function CookieModal(props) {
    const { handleCookieModal, acceptCookie } = props;

    return(
        <article className={styles.article}>
            <div className={styles.backdrop}></div>
            <div className={styles.modalbox} color="bg-white">
                <div className={styles.closeWrap}>
                    <button className={styles.button} onClick={handleCookieModal}>
                        <Icons closeModal />
                    </button>
                </div>
                <div className={styles.content}>
                    <div className={styles.imgbox}>
                        <img src={ImgCookie} alt="Cookie Policy" />
                    </div>
                    <div className={styles.textbox}>
                        <p>We use cookies to ensure the best possible experience. By using our site you agree with our <Link to="/cookie-policy">Cookie Policy.</Link></p>
                        <div className={styles.buttons}>
                            <button className="button" color="bg-primary" onClick={acceptCookie}>Accept Cookies</button>
                            <button className="button" color="primary-border" onClick={handleCookieModal}>Decline Cookies</button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default CookieModal;
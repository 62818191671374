import Img1Inventory from "./../../../assets/images/inventory.gif";
// import Img2Inventory from "./../../../assets/images/img1_forecast.png";
import styles from "./index.module.css";

function SectionInventory(props) {
  const { handleModal } = props;

  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className="containbox">
          <h2 className="mobTabOnly">Streamline your kitchen operations</h2>
          <div className={`${styles.content} imgLeft`}>
            <div>
              <div className={styles.imgbox}>
                <img src={Img1Inventory} alt="Inventory GIF" />
              </div>
            </div>
            <div>
              <h2>Streamline your kitchen operations</h2>
              <p className="h5">
                ZEWST KDS helps you streamline your kitchen operations and make
                them more efficient .A kitchen display system eliminates
                problems by showcasing everything food preparation staff needs
                to know about each customer's order.
              </p>
              <button
                className="button"
                color="black-border"
                onClick={handleModal}
              >
                Request A Demo
              </button>
            </div>
          </div>
          {/* <div className={styles.imgbox1}>
            <img src={Img2Inventory} alt="Inventory PNG" />
          </div> */}
        </div>
      </article>
    </section>
  );
}

export default SectionInventory;

import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import "./assets/fonts/sfprotext/SF-Pro-Text-Bold.ttf";
import "./assets/fonts/sfprotext/SF-Pro-Text-Semibold.ttf";
import "./assets/fonts/sfprotext/SF-Pro-Text-Medium.ttf";
import "./assets/fonts/sfprotext/SF-Pro-Text-Regular.ttf";
import "./assets/styles/index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.Fragment>
);

import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>About Us</h2>
          <p>
            Zewst is a platform for Restaurant Brands to launch and manage their
            physical and virtual restaurants.
          </p>
          <p>
            With Zewst, restaurant owners can launch brands seamlessly as well
            as franchise their existing restaurant concepts with ease via a
            single platform. We provide Online Store (at brand level), POS
            terminals (at branch level), and a robust AI enabled Restaurant
            Management System for brand and branch managers to manage their
            restaurant's day to day operations.
          </p>
          <p>
            Our mission is simple: we want to make it easy for aspiring
            restaurant owners to open and run their restaurant.
          </p>
          <p>
            At Zewst, we believe that the best way to grow your restaurant
            business is to have a platform that allows you to manage all of your
            restaurant brands from one place, and then use our online tools to
            launch websites for those brands.
          </p>
          <p>
            We know what it's like to have multiple restaurants in the same
            building, or even spread out across several states. That's why we
            offer support for managing Franchisee and Franchisor relationships,
            automatic royalty transfers, and distributing funds to various
            involved parties based on pre agreed criteria.
          </p>
          <p>
            Our online tools are specifically designed with restaurant owners in
            mind. We know that you have enough on your plate without having to
            worry about launching websites or managing online ordering services.
            That's why we've developed a website builder that makes it easy for
            you to build your own website for each restaurant brand in just
            minutes!
          </p>
          <p>
            At Zewst, we believe that every restaurant should be able to enjoy
            the benefits of automation. That's why we created our Restaurant
            Management System, which includes AI Sales Prediction, AI Food Waste
            Prediction, Recipe Engineering, Inventory Management, Kitchen
            Display Screen and Delivery Partner Integration
          </p>
          <p>
            <strong>Zewst Restaurant Management System</strong>
          </p>
          <ul className="list1">
            <li>
              <p>
                AI Sales Prediction: predicts what food items will sell the most
                based on your sales history and menu, so you can make better
                decisions about your inventory. You can also see how long it
                takes to sell each item and which are the most popular with
                customers.
              </p>
            </li>
            <li>
              <p>
                AI Food Waste Prediction: predicts how much food you'll waste
                based on what's left over from previous days, so you can plan
                for more or less depending on what's needed. It also tells you
                when food is going to expire, so you know exactly when to throw
                it away and not have any waste!
              </p>
            </li>
            <li>
              <p>
                Recipe Engineering: allows you to save recipes for the menu
                items.
              </p>
            </li>
            <li>
              <p>
                Inventory Management: keeps track of your inventory and lets you
                know if something is running low so that there aren't any
                surprises during service or delivery. You'll never have to worry
                about running out of ingredients again!
              </p>
            </li>
            <li>
              <p>
                Kitchen Display Screen: allows kitchen staff to display
                information about orders as they come in, including price and
                quantity (so there's no confusion).{" "}
              </p>
            </li>
          </ul>

          <Link to="/" className="button" color="black-border">
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default AboutUs;

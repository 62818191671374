import { Link } from 'react-router-dom';
import Icons from './../icons';
import styles from './index.module.css';

export const Header = () => {

    return (
        <>
            {/* <article className={styles.headerBefore}></article> */}
            <header className={styles.header}>
                <article className="containbox">
                    <div className={styles.flex}>
                        {/* <div className={styles.colEqual}>
                            <button className={styles.link}>
                                <span className={styles.menuIkon}>
                                    <Icons menuIkon />
                                </span>
                            </button>
                        </div> */}
                        <div>
                            <div className={styles.link}>
                                <span className={styles.logo}>
                                    <Link to="/"><Icons logoIkon /></Link>
                                </span>
                            </div>
                        </div>
                        {/* <div>
                            <button className={`${styles.link} ${styles.flexLink}`}>
                                <span className={styles.profileIkon}>
                                    <Icons profileIkon />
                                </span>
                                <span className={styles.ddArrowIkon}>
                                    <Icons ddArrowIkon />
                                </span>
                            </button>
                        </div> */}
                    </div>
                </article>
            </header>
        </>
    );
}

export const Header2 = (props) => {
    const { handleModal } = props;

    return (
        <>
            <header className={styles.header2}>
                <article className="containbox">
                    <div className={`${styles.flex} ${styles.flexHead2}`}>
                        <div>
                            <div className={styles.link}>
                                <span className={styles.logo}>
                                    <Link to="/"><Icons logoIkon /></Link>
                                </span>
                            </div>
                        </div>
                        <div className={styles.head2Right}>
                            <div>
                                <Link to="/"><Icons home /></Link>
                            </div>
                            <div>
                                <button
                                    className="button"
                                    color="white-border"
                                    onClick={handleModal}
                                    >
                                    Request A Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </header>
        </>
    );
}
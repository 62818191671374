import Img1Recipe from "./../../../assets/images/recipe_management.gif";
import styles from "./index.module.css";

function SectionRecipe(props) {
  const { handleModal } = props;

  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className="containbox">
          <h2 className="mobTabOnly">Recipe Management</h2>
          <div className={`${styles.content}`}>
            <div>
              <div className={styles.imgbox}>
                <img src={Img1Recipe} alt="Forecast Sales" />
              </div>
            </div>
            <div>
              <h2>Recipe Management</h2>
              <p className="h5">
                Track your recipes, manage your batches and mixture with ZEWST
                recipe management. Recipe management is complicated and prone to
                error. We help you create, cost, and manage recipe yield with
                ease. Use it to accurately convert all ingredients in the units
                of measurement your staff uses to cook. Share recipes across
                locations to ensure each dish is the same size and flavor, no
                matter where your customer consumes it.
              </p>
              <button
                className="button"
                color="black-border"
                onClick={handleModal}
              >
                Request A Demo
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionRecipe;

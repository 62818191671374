import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Layout1, Layout2 } from "./layout";
import Home from "./views/home";
import TermsConditions from "./views/termsconditions";
import PrivacyPolicy from "./views/privacypolicy";
import RefundPolicy from "./views/refundpolicy";
import Subscriptions from "./views/subscriptions";
import CookiePolicy from "./views/cookiepolicy";
import AboutUs from "./views/aboutus";
import Footer from "./components/footer";
import RequestModal from "./components/modal";
import CookieModal from "./components/cookiemodal";
import PrivacyPolicyZewstDisplay from "./views/privacypolicy_zewst_display";
import Accessrequest from "./views/access_request";

function App() {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/6244353c0bfe3f4a8770735d/1fvd6vtq0";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
  const [cookies, setCookie] = useCookies(["ZEWST"]);
  const [cookieModalDecline, setCookieModalDecline] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const bodyTag = document.getElementsByTagName("body")[0];

  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleCookieModal = () => {
    setShowCookieModal(false);
    setCookieModalDecline(true);
  };
  const acceptCookie = () => {
    setCookie("ZEWST", "lalalalla", { path: "/" });
    setShowCookieModal(false);
  };
  showModal || showCookieModal ? bodyTag.classList.add("noscroll") : bodyTag.classList.remove("noscroll");

  const onScroll = () => {
    window.pageYOffset > 600 && cookies?.ZEWST === undefined ? setShowCookieModal(true) : setShowCookieModal(false);
  };

  useEffect(() => {
    if (!cookieModalDecline) {
      cookies?.ZEWST || window.pageYOffset < 600 ? setShowCookieModal(false) : setShowCookieModal(true);
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [showCookieModal, cookies, cookieModalDecline]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout1>
              <Home handleModal={handleModal} />
            </Layout1>
          }
        />
        <Route
          path="terms-conditions"
          element={
            <Layout2 handleModal={handleModal}>
              <TermsConditions />
            </Layout2>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Layout2 handleModal={handleModal}>
              <PrivacyPolicy />
            </Layout2>
          }
        />
        <Route
          path="privacy-policy-zewst-display"
          element={
            <Layout2 handleModal={handleModal}>
              <PrivacyPolicyZewstDisplay />
            </Layout2>
          }
        />
        <Route
          path="refund-policy"
          element={
            <Layout2 handleModal={handleModal}>
              <RefundPolicy />
            </Layout2>
          }
        />
        <Route
          path="about-us"
          element={
            <Layout2 handleModal={handleModal}>
              <AboutUs />
            </Layout2>
          }
        />
        <Route
          path="subscriptions"
          element={
            <Layout2 handleModal={handleModal}>
              <Subscriptions />
            </Layout2>
          }
        />
        <Route
          path="access-request"
          element={
            <Layout2 handleModal={handleModal}>
              <Accessrequest />
            </Layout2>
          }
        />
        <Route
          path="cookie-policy"
          element={
            <Layout2 handleModal={handleModal}>
              <CookiePolicy setShowCookieModal={setShowCookieModal} />
            </Layout2>
          }
        />
      </Routes>
      <Footer handleModal={handleModal} />
      {showModal && <RequestModal handleModal={handleModal} />}
      {showCookieModal && <CookieModal handleCookieModal={handleCookieModal} acceptCookie={acceptCookie} />}
    </BrowserRouter>
  );
}

export default App;

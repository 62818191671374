import { useState } from "react";
import { Link } from "react-router-dom";

function Accessrequest() {
  const [law, setLaw] = useState("OTHER");
  const _onHandleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const res = await fetch("http://localhost:3000", {
      method: "POST",
      body: formData,
    });
    console.log(await res.json());
  };

  const _onHandleLawChange = (e) => {
    setLaw(e.target.value);
  };
  const _renderLawSubmission = () => {
    if (law === "CCPA") {
      return (
        <div className="input-group">
          <label>I am submitting a request to ___________</label>
          <div className="radio-group">
            <input required name="CCPA_submission_request" type="radio" value="Know what information is being collected from me" />
            <label>Know what information is being collected from me</label>
          </div>
          <div className="radio-group">
            <input required name="CCPA_submission_request" type="radio" value="Have my information deleted" />
            <label>Have my information deleted</label>
          </div>
          <div className="radio-group">
            <input required name="CCPA_submission_request" type="radio" value="Opt out of having my data sold to third parties" />
            <label>Opt out of having my data sold to third parties</label>
          </div>
          <div className="radio-group">
            <input required name="CCPA_submission_request" type="radio" value="Opt in to the sale of my personal data" />
            <label>Opt in to the sale of my personal data</label>
          </div>
          <div className="radio-group">
            <input required name="CCPA_submission_request" type="radio" value="Other (please specify in the comment box below)" />
            <label>Other (please specify in the comment box below)</label>
          </div>
        </div>
      );
    } else if (law === "GDPR") {
      return (
        <div className="input-group">
          <label>I am submitting a request to ___________</label>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Confirm that my personal information is being processed" />
            <label>Confirm that my personal information is being processed</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Access my personal information" />
            <label>Access my personal information</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Edit / correct my personal information" />
            <label>Edit / correct my personal information</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Have my personal information deleted" />
            <label>Have my personal information deleted</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Restrict the processing of my personal information" />
            <label>Restrict the processing of my personal information</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Ask a question about Zewst's privacy policy" />
            <label>Ask a question about Zewst's privacy policy</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Withdraw my consent to the processing of my personal information" />
            <label>Withdraw my consent to the processing of my personal information</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Deny Zewst the right to use my personal information for purposes of direct marketing, including profiling" />
            <label>Deny Zewst the right to use my personal information for purposes of direct marketing, including profiling</label>
          </div>
          <div className="radio-group">
            <input required name="GDPR_submission_request" type="radio" value="Other (please specify in the comment box below)" />
            <label>Other (please specify in the comment box below)</label>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <article color="bg-white">
      <div className="containbox">
        <div className="content_on_white">
          <h2>Data Subject Access Request Form</h2>
          <p>Please fill in the information below. The website administrator or data protection officer will be notified of your request within 24 hours, and will need an appropriate amount of time to respond.</p>
          <div className="access-request-container">
            <form onSubmit={_onHandleSubmit}>
              <div className="input-group">
                <label>Website</label>
                <input required name="website" placeholder="Enter Website Name" defaultValue="Zewst" />
              </div>
              <div className="input-group">
                <label>Your Name</label>
                <input required name="fullName" type="text" placeholder="Enter Your Name" />
              </div>
              <div className="input-group">
                <label>What email address do you use to access the above website / app?</label>
                <input required name="email" type="email" placeholder="Enter Your Email Address" />
              </div>
              <div className="input-group">
                <label>You are submitting this request as</label>
                <div className="radio-group">
                  <input required type="radio" name="submitting_as" value="The person, or the parent / guardian of the person, whose name appears above." />
                  <label>The person, or the parent / guardian of the person, whose name appears above.</label>
                </div>
                <div className="radio-group">
                  <input required type="radio" name="submitting_as" value="An agent authorized by the consumer to make this request on their behalf." />
                  <label>An agent authorized by the consumer to make this request on their behalf.</label>
                </div>
              </div>
              <div className="input-group">
                <label>Under the rights of which law are you making this request?</label>
                <select required defaultValue={law} name="law" onChange={_onHandleLawChange}>
                  <option value="GDPR">GDPR</option>
                  <option value="CCPA">CCPA</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </div>
              {_renderLawSubmission()}
              <div className="input-group">
                <label>Please leave details regarding your action request or question.</label>
                <textarea name="questions" className="input-textarea" placeholder="Please leave details regarding your action request or question." />
              </div>
              <div className="input-group">
                <label>I confirm that</label>
                <div className="radio-group">
                  <input required name="information_declaration" type="checkbox" value="Under penalty of perjury, I declare all the above information to be true and accurate." />
                  <label>Under penalty of perjury, I declare all the above information to be true and accurate.</label>
                </div>
                <div className="radio-group">
                  <input required name="termination_declatration" type="checkbox" value="I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with Zewst." />
                  <label>I understand that the deletion or restriction of my personal data is irreversible and may result in the termination of services with Zewst.</label>
                </div>
                <div className="radio-group">
                  <input required type="checkbox" name="contact_declaration" value="I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request." />
                  <label>I understand that I will be required to validate my request by email, and I may be contacted in order to complete the request.</label>
                </div>
              </div>
              <div className="submit-button-container">
                <button className="button-5" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <Link to="/" className="button" color="black-border">
            Go back to Home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default Accessrequest;

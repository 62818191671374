import styles from "./index.module.css";
import ImgBanner from "./../../../assets/images/img_banner.png";
import ImgBannerMobile from "./../../../assets/images/img_banner_mobile.png";

import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

function SectionWelcome(props) {
  const { handleModal } = props;

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      console.log("🚀 ~ file: index.js:14 ~ cal", cal);
      cal("ui", {
        theme: "light",
        styles: { branding: { brandColor: "#7A30B3" } },
        hideEventTypeDetails: false,
      });
    })();
  }, []);

  return (
    <section className={styles.section}>
      <article>
        <div className="containbox">
          <div className={styles.content}>
            <h3>A One Stop Solution for</h3>
            <h1>
              Restaurant <span>Management</span>
            </h1>
            {/* <h1>Food Waste <span>Management</span></h1> */}
            <button
              className="button buttonLg"
              color="white-border"
              //onClick={handleModal}
              data-cal-link="ubaidrajputt/30min"
            >
              Request A Demo
            </button>
          </div>
        </div>
        <div className={styles.imgbanner}>
          <img src={ImgBanner} alt="Banner GIF" className={styles.img_desk} />
          <img src={ImgBannerMobile} alt="" className={styles.img_mob} />
        </div>
      </article>
    </section>
  );
}

export default SectionWelcome;

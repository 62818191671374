import { Link } from "react-router-dom";
import Icons from "../icons";
import styles from "./index.module.css";

function Footer(props) {
  const { handleModal } = props;

  return (
    <footer className={styles.footer}>
      <article className={styles.article}>
        <div className="container">
          <div className={styles.flex}>
            <div className={styles.fooLeft}>
              <div>
                <Link to="/">
                  <Icons logoIkon />
                </Link>
              </div>
              <div className={styles.socials}>
                <a href="/">
                  <Icons twitter />
                </a>
                <a href="/">
                  <Icons facebook />
                </a>
                <a href="/">
                  <Icons instagram />
                </a>
              </div>
              <div className={styles.foolinks}>
                <p className={styles.copyRight}>©2022 ZEWST</p>
                <p>
                  <Link to="/terms-conditions">Terms</Link>
                </p>
                <p>|</p>
                <p>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
                <p>|</p>
                <p>
                  <Link to="/refund-policy">Refund Policy</Link>
                </p>
                <p>|</p>
                <p>
                  <Link to="/subscriptions">Subscriptions</Link>
                </p>
                <p>|</p>
                <p>
                  <Link to="/about-us">About Us</Link>
                </p>
              </div>
              <div className={styles.foocontactlinks}>
                <p>
                  <a
                    className={styles.foocontacthref}
                    href="mailto:info@zewst.com"
                  >
                    <Icons email />
                    <span>info@zewst.com</span>
                  </a>
                </p>
                <p>|</p>
                <p>
                  <a className={styles.foocontacthref}>
                    <Icons phone />
                    <span>(888) 899-3978</span>
                  </a>
                </p>
                <p>|</p>
                <p>
                  <a
                    className={styles.foocontacthref}
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <Icons home />
                    <span>49 Mattawang Dr Somerset, NJ 08873</span>
                  </a>
                </p>
              </div>
            </div>
            <div className={styles.fooRight}>
              <div>
                <button
                  className="button"
                  color="white-border"
                  onClick={handleModal}
                >
                  Request a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </footer>
  );
}

export default Footer;

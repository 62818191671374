import { baseUrl } from "../utilities/helpers/constants";

export const requestDemo = async (data) => {
  const { name, resturantName, email, phone, address } = data;
  try {
    const data = await fetch(`${baseUrl}/appdemo/manual/add`, {
      method: "POST",
      body: JSON.stringify({
        name,
        resturantName,
        email,
        phone,
        address,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    // if(data.status === 200){
    //     alert("your data has been saved");
    // }
    return data.status;

  } catch (e) {
    //alert("some error occoured, please try again later");
    return "some error occoured, please try again later";
  }
};

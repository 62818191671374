import Img3Forecast from "./../../../assets/images/forecast_sales.gif";
import styles from "./index.module.css";

function SectionForecast(props) {
  const { handleModal } = props;

  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className="containbox">
          <h2 className="mobTabOnly">Inventory Management</h2>
          <div className={`${styles.content}`}>
            <div>
              <h2>Inventory Management</h2>
              <p className="h5">
                ZEWST helps you automate your restaurant inventory management
                but helping you keep food and beverage stock accurate down to
                the raw ingredients. Every sale registered in your website
                automatically depletes inventory down to the raw ingredients. By
                ordering from our partner inventory suppliers, get your
                inventory updated automatically without any hassle. ZEWST helps
                you with demand planning by analyzing the trends and known
                upcoming events and predicts needed inventory levels for a
                future period.
              </p>
              <button
                className="button"
                color="primary-border"
                onClick={handleModal}
              >
                Request A Demo
              </button>
            </div>
            <div>
              <div className={styles.imgbox}>
                <img src={Img3Forecast} alt="Forecast Sales" />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionForecast;

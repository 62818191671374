import Img1FoodWaste from "./../../../assets/images/food_waste_management.gif";
import styles from "./index.module.css";

function SectionFoodWaste(props) {
  const { handleModal } = props;

  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <div className="containbox">
          <h2 className="mobTabOnly">Launch and manage your cloud kitchen</h2>
          <div className={`${styles.content} imgRight`}>
            <div>
              <h2>Launch and manage your cloud kitchen</h2>
              <p className="h5">
                Cloud kitchens enable restauranteurs to expand an existing
                restaurant or start a virtual brand at minimal cost. This
                provides restauranteurs with the opportunity to scale, explore
                new markets, or trial new concepts.
              </p>
              <button
                className="button"
                color="primary-border"
                onClick={handleModal}
              >
                Request A Demo
              </button>
            </div>
            <div>
              <div className={styles.imgbox}>
                <img src={Img1FoodWaste} alt="Waste Prediction" />
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default SectionFoodWaste;
